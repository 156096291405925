var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      staticClass: "filter-form",
      attrs: { model: _vm.filters, "label-position": "top" }
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "IP" } },
        [
          _c("el-input", {
            directives: [
              {
                name: "debounce",
                rawName: "v-debounce",
                value: _vm.doFilter,
                expression: "doFilter"
              }
            ],
            model: {
              value: _vm.filters.ip,
              callback: function($$v) {
                _vm.$set(
                  _vm.filters,
                  "ip",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "filters.ip"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Owner" } },
        [
          _c("el-input", {
            directives: [
              {
                name: "debounce",
                rawName: "v-debounce",
                value: _vm.doFilter,
                expression: "doFilter"
              }
            ],
            model: {
              value: _vm.filters.owner,
              callback: function($$v) {
                _vm.$set(
                  _vm.filters,
                  "owner",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "filters.owner"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Country" } },
        [
          _c("el-input", {
            directives: [
              {
                name: "debounce",
                rawName: "v-debounce",
                value: _vm.doFilter,
                expression: "doFilter"
              }
            ],
            model: {
              value: _vm.filters.country,
              callback: function($$v) {
                _vm.$set(
                  _vm.filters,
                  "country",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "filters.country"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Region" } },
        [
          _c("el-input", {
            directives: [
              {
                name: "debounce",
                rawName: "v-debounce",
                value: _vm.doFilter,
                expression: "doFilter"
              }
            ],
            model: {
              value: _vm.filters.region,
              callback: function($$v) {
                _vm.$set(
                  _vm.filters,
                  "region",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "filters.region"
            }
          })
        ],
        1
      ),
      _vm.isFiltersChanged
        ? _c(
            "el-button",
            { attrs: { type: "gray" }, on: { click: _vm.clearFilters } },
            [_vm._v(" Reset filters ")]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }