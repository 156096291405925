var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ]
    },
    [
      (_vm.list && _vm.list.length) || _vm.loading
        ? _c("div", { staticClass: "table-wrapper" }, [
            _c("table", { staticClass: "table" }, [
              _c("thead", [
                _c("tr", [
                  _c(
                    "th",
                    [
                      _c("SortLabel", {
                        attrs: {
                          label: "Title",
                          order: _vm.$_sortingMixin_checkOrder("title")
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.doSort("title")
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "th",
                    [
                      _c("SortLabel", {
                        attrs: {
                          label: "Address",
                          order: _vm.$_sortingMixin_checkOrder("address")
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.doSort("address")
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "th",
                    [
                      _c("SortLabel", {
                        attrs: {
                          label: "Website",
                          order: _vm.$_sortingMixin_checkOrder("website")
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.doSort("website")
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "th",
                    [
                      _c("SortLabel", {
                        attrs: {
                          label: "Phone",
                          order: _vm.$_sortingMixin_checkOrder("phone")
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.doSort("phone")
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "th",
                    [
                      _c("SortLabel", {
                        attrs: {
                          label: "Campaign",
                          order: _vm.$_sortingMixin_checkOrder("campaign.name")
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.doSort("campaign.name")
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "th",
                    [
                      _c("SortLabel", {
                        attrs: {
                          label: "Email",
                          order: _vm.$_sortingMixin_checkOrder("email")
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.doSort("email")
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "th",
                    { staticClass: "text-center" },
                    [
                      _c("SortLabel", {
                        attrs: {
                          label: "Was changed",
                          order: _vm.$_sortingMixin_checkOrder("changed")
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.doSort("changed")
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "th",
                    [
                      _c("SortLabel", {
                        attrs: {
                          label: "Last update",
                          order: _vm.$_sortingMixin_checkOrder("updated_at")
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.doSort("updated_at")
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "th",
                    [
                      _c("SortLabel", {
                        attrs: {
                          label: "Review link",
                          order: _vm.$_sortingMixin_checkOrder("review_link")
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.doSort("review_link")
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "th",
                    { staticClass: "text-center" },
                    [
                      _c("SortLabel", {
                        attrs: {
                          label: "Reviews Count",
                          order: _vm.$_sortingMixin_checkOrder("reviews_count")
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.doSort("reviews_count")
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "th",
                    [
                      _c("SortLabel", {
                        attrs: {
                          label: "Recovery email",
                          order: _vm.$_sortingMixin_checkOrder("recovery_email")
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.doSort("recovery_email")
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "th",
                    [
                      _c("SortLabel", {
                        attrs: {
                          label: "Notes",
                          order: _vm.$_sortingMixin_checkOrder("notes")
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.doSort("notes")
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "th",
                    [
                      _c("SortLabel", {
                        attrs: {
                          label: "Status",
                          order: _vm.$_sortingMixin_checkOrder("status")
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.doSort("status")
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "th",
                    [
                      _c("SortLabel", {
                        attrs: {
                          label: "Client owned",
                          order: _vm.$_sortingMixin_checkOrder("client_owned")
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.doSort("client_owned")
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "th",
                    [
                      _c("SortLabel", {
                        attrs: {
                          label: "Client location",
                          order: _vm.$_sortingMixin_checkOrder(
                            "is_client_location"
                          )
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.doSort("is_client_location")
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c("th", { staticClass: "fixed-right-column text-center" }, [
                    _vm._v(" Actions ")
                  ])
                ])
              ]),
              _vm.list
                ? _c(
                    "tbody",
                    _vm._l(_vm.list, function(item) {
                      return _c(
                        "tr",
                        {
                          key: item.id,
                          class: { "tr-suspended": item.changed }
                        },
                        [
                          _c("td", { staticClass: "cell-title" }, [
                            _vm._v(" " + _vm._s(item.title) + " ")
                          ]),
                          _c("td", { staticClass: "cell-address" }, [
                            _vm._v(" " + _vm._s(item.address) + " ")
                          ]),
                          _c("td", [
                            item.website
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "external-link",
                                    attrs: {
                                      href: item.website,
                                      target: "_blank"
                                    }
                                  },
                                  [
                                    _vm._v(" " + _vm._s(item.website) + " "),
                                    _c("i", {
                                      staticClass: "fas fa-external-link-alt"
                                    })
                                  ]
                                )
                              : _c("div", [_vm._v(" — ")])
                          ]),
                          _c("td", { staticClass: "cell-phone" }, [
                            _vm._v(" " + _vm._s(item.phone) + " ")
                          ]),
                          _c("td", [
                            _vm._v(
                              " " +
                                _vm._s(item.campaign && item.campaign.name) +
                                " "
                            )
                          ]),
                          _c("td", [_vm._v(" " + _vm._s(item.email) + " ")]),
                          _c("td", { staticClass: "text-center" }, [
                            _vm._v(
                              " " + _vm._s(item.changed ? "Yes" : "No") + " "
                            )
                          ]),
                          _c("td", { staticClass: "cell-updated-at" }, [
                            _vm._v(" " + _vm._s(item.updated_at) + " ")
                          ]),
                          _c("td", [
                            _vm._v(" " + _vm._s(item.review_link) + " ")
                          ]),
                          _c("td", { staticClass: "text-center" }, [
                            _vm._v(" " + _vm._s(item.reviews_count) + " ")
                          ]),
                          _c("td", [
                            _vm._v(" " + _vm._s(item.recovery_email) + " ")
                          ]),
                          _c("td", [_vm._v(" " + _vm._s(item.notes) + " ")]),
                          _c("td", [
                            _vm._v(
                              " " + _vm._s(_vm.getStatusById(item.status)) + " "
                            )
                          ]),
                          _c(
                            "td",
                            [
                              _c("el-switch", {
                                attrs: { "active-color": "#67C23A" },
                                on: {
                                  change: function($event) {
                                    return _vm.changeClientOwned(item.id)
                                  }
                                },
                                model: {
                                  value: item.client_owned,
                                  callback: function($$v) {
                                    _vm.$set(item, "client_owned", $$v)
                                  },
                                  expression: "item.client_owned"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "td",
                            [
                              _c("el-switch", {
                                attrs: { "active-color": "#67C23A" },
                                on: {
                                  change: function($event) {
                                    return _vm.changeClientLocation(item.id)
                                  }
                                },
                                model: {
                                  value: item.is_client_location,
                                  callback: function($$v) {
                                    _vm.$set(item, "is_client_location", $$v)
                                  },
                                  expression: "item.is_client_location"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "td",
                            {
                              staticClass:
                                "fixed-right-column table__action-column"
                            },
                            [
                              _c(
                                "el-button-group",
                                [
                                  _c("el-button", {
                                    attrs: {
                                      type: "primary",
                                      size: "mini",
                                      icon: "el-icon-edit",
                                      title: "Edit"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.handleEdit(item)
                                      }
                                    }
                                  }),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "gray",
                                        size: "mini",
                                        title: "Show Logs",
                                        icon: "el-icon-tickets"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.handleLogs(item)
                                        }
                                      }
                                    },
                                    [_vm._v(" Logs ")]
                                  ),
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: item.link,
                                        target: "_blank"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "gray-alt",
                                            size: "mini",
                                            title: "View Campaign GMB",
                                            icon: "el-icon-map-location"
                                          }
                                        },
                                        [_vm._v(" GMap ")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c("el-button", {
                                    attrs: {
                                      type: "danger",
                                      size: "mini",
                                      title: "Delete",
                                      icon: "el-icon-delete"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.handleDelete(item.id)
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e()
            ])
          ])
        : _c("EmptyList")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }