var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      staticClass: "filter-form",
      attrs: { model: _vm.filters, "label-position": "top" }
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "Show by" } },
        [
          _c(
            "el-radio-group",
            {
              on: { change: _vm.doFilter },
              model: {
                value: _vm.filters.group,
                callback: function($$v) {
                  _vm.$set(_vm.filters, "group", $$v)
                },
                expression: "filters.group"
              }
            },
            [
              _c("el-radio-button", { attrs: { label: "week" } }, [
                _vm._v("Week")
              ]),
              _c("el-radio-button", { attrs: { label: "month" } }, [
                _vm._v("Month")
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Campaign categories" } },
        [
          _c(
            "el-select",
            {
              staticClass: "multiselect",
              attrs: { filterable: "", multiple: "" },
              on: { change: _vm.doFilter },
              model: {
                value: _vm.filters.campaign_category_ids,
                callback: function($$v) {
                  _vm.$set(_vm.filters, "campaign_category_ids", $$v)
                },
                expression: "filters.campaign_category_ids"
              }
            },
            _vm._l(_vm.campaignCategories, function(category) {
              return _c("el-option", {
                key: category.id,
                attrs: { label: category.name, value: category.id }
              })
            }),
            1
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Date Range" } },
        [
          _c(
            "el-select",
            {
              attrs: { filterable: "" },
              on: { change: _vm.changeDatePresets },
              model: {
                value: _vm.filters.date_preset,
                callback: function($$v) {
                  _vm.$set(_vm.filters, "date_preset", $$v)
                },
                expression: "filters.date_preset"
              }
            },
            _vm._l(_vm.dateRangePresets, function(item) {
              return _c("el-option", {
                key: item.label,
                attrs: { label: item.label, value: item.value }
              })
            }),
            1
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isCustomDateRangeVisible,
              expression: "isCustomDateRangeVisible"
            }
          ],
          attrs: { label: "Custom Date Range" }
        },
        [
          _c("el-date-picker", {
            staticClass: "date-range-picker",
            attrs: {
              clearable: false,
              "end-placeholder": "End date",
              format: "MM/dd/yyyy",
              "range-separator": "To",
              "start-placeholder": "Start date",
              type: "daterange",
              "value-format": "yyyy-MM-dd"
            },
            on: { change: _vm.doFilter },
            model: {
              value: _vm.filters.date_range,
              callback: function($$v) {
                _vm.$set(_vm.filters, "date_range", $$v)
              },
              expression: "filters.date_range"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Chart Type" } },
        [
          _c(
            "el-radio-group",
            { attrs: { value: _vm.chartType } },
            [
              _c(
                "el-radio-button",
                {
                  attrs: { label: "line" },
                  nativeOn: {
                    click: function($event) {
                      return _vm.$emit("change-chart-type", "line")
                    }
                  }
                },
                [_vm._v("Line")]
              ),
              _c(
                "el-radio-button",
                {
                  attrs: { label: "bar" },
                  nativeOn: {
                    click: function($event) {
                      return _vm.$emit("change-chart-type", "bar")
                    }
                  }
                },
                [_vm._v("Bar")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.isFiltersChanged
        ? _c(
            "el-button",
            { attrs: { type: "gray" }, on: { click: _vm.clearFilters } },
            [_vm._v(" Reset filters ")]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }