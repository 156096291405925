var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      attrs: { model: _vm.form, rules: _vm.rules, "label-position": "top" }
    },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.visible,
            "before-close": _vm.handleClose,
            title: _vm.isEdit ? "Edit Network Site" : "Create Network Site",
            width: "94%"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "URL", prop: "url" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.url,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "url", $$v)
                  },
                  expression: "form.url"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Category", prop: "category_id" } },
            [
              _c(
                "el-select",
                {
                  attrs: { filterable: "" },
                  model: {
                    value: _vm.form.category_id,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "category_id", $$v)
                    },
                    expression: "form.category_id"
                  }
                },
                _vm._l(_vm.networkCategories.data, function(category) {
                  return _c("el-option", {
                    key: category.id,
                    attrs: { label: category.name, value: category.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("Cancel")
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                [_vm._v("Save")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }