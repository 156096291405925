var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _vm.user
      ? _c("p", { staticClass: "lead" }, [
          _vm._v(" Hello " + _vm._s(_vm.user.username) + " "),
          _vm.user.isSuperAdmin
            ? _c(
                "ul",
                _vm._l(_vm.user.tools_links, function(link, key) {
                  return _c("li", { key: key }, [
                    _c("a", { attrs: { href: link.url, target: "_blank" } }, [
                      _vm._v(_vm._s(link.label))
                    ])
                  ])
                }),
                0
              )
            : _vm._e()
        ])
      : _c("p", { staticClass: "lead" }, [
          _vm._v(" Hello guest, thank you for installing me. ")
        ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-title-group" }, [
      _c("h1", [_vm._v("Dashboard")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }