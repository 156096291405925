var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ]
    },
    [
      (_vm.log && _vm.log.length) || _vm.loading
        ? _c("div", { staticClass: "table-wrapper" }, [
            _c("table", { staticClass: "table" }, [
              _vm._m(0),
              _vm.log
                ? _c(
                    "tbody",
                    _vm._l(_vm.log, function(item) {
                      return _c("tr", { key: item.id }, [
                        _c("td", [_vm._v(" " + _vm._s(item.created_at) + " ")]),
                        _c("td", [
                          _c(
                            "div",
                            {
                              class: +item.status
                                ? "text-success"
                                : "text-danger"
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.getSiteStatusById(item.status)) +
                                  " "
                              )
                            ]
                          )
                        ])
                      ])
                    }),
                    0
                  )
                : _vm._e()
            ])
          ])
        : _c("EmptyList")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [_c("th", [_vm._v("Time")]), _c("th", [_vm._v("Status")])])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }