var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      staticClass: "filter-form",
      attrs: { model: _vm.filters, "label-position": "top" }
    },
    [
      _c(
        "el-form-item",
        { staticClass: "filter-form__form-item" },
        [
          _c("el-input", {
            directives: [
              {
                name: "debounce",
                rawName: "v-debounce",
                value: _vm.doFilter,
                expression: "doFilter"
              }
            ],
            staticClass: "search-input",
            attrs: {
              placeholder: _vm.placeholder,
              "prefix-icon": "el-icon-search"
            },
            model: {
              value: _vm.filters.q,
              callback: function($$v) {
                _vm.$set(_vm.filters, "q", $$v)
              },
              expression: "filters.q"
            }
          }),
          _vm.isFiltersChanged
            ? _c(
                "div",
                {
                  staticClass: "filter-form__clear-icon",
                  on: { click: _vm.clearFilters }
                },
                [_c("i", { staticClass: "el-icon-close" })]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }