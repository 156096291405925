var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("nav", { staticClass: "navbar navbar-default navbar-static-top" }, [
    _c("div", { staticClass: "container-fluid" }, [
      _vm._m(0),
      _c(
        "div",
        {
          staticClass: "collapse navbar-collapse",
          attrs: { id: "app-navbar-collapse" }
        },
        [
          _c("ul", { staticClass: "nav navbar-nav" }, [_vm._v("   ")]),
          _c("ul", { staticClass: "nav navbar-nav navbar-right" }, [
            _vm.isGuest
              ? _c(
                  "li",
                  [
                    _c("router-link", { attrs: { to: { name: "Login" } } }, [
                      _c("i", { staticClass: "fas fa-sign-in-alt" }),
                      _vm._v("Log in ")
                    ])
                  ],
                  1
                )
              : _vm._e(),
            _vm.user
              ? _c("li", [
                  _c(
                    "a",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.$emit("edit-profile")
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "fa fa-user-circle" }),
                      _vm._v(_vm._s(_vm.user.username) + " ")
                    ]
                  )
                ])
              : _vm._e(),
            _vm._m(1),
            _vm._m(2),
            !_vm.isGuest
              ? _c("li", [
                  _c(
                    "a",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.logout.apply(null, arguments)
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "fas fa-sign-out-alt" }),
                      _vm._v(" Log out ")
                    ]
                  )
                ])
              : _vm._e()
          ])
        ]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "navbar-header" }, [
      _c(
        "button",
        {
          staticClass: "navbar-toggle collapsed",
          attrs: {
            type: "button",
            "data-toggle": "collapse",
            "data-target": "#app-navbar-collapse"
          }
        },
        [
          _c("span", { staticClass: "sr-only" }, [_vm._v("Toggle Navigation")]),
          _c("span", { staticClass: "icon-bar" }),
          _c("span", { staticClass: "icon-bar" }),
          _c("span", { staticClass: "icon-bar" })
        ]
      ),
      _c("a", { staticClass: "navbar-brand", attrs: { href: "/" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", {}, [
      _c(
        "a",
        {
          attrs: {
            href: "https://www.markmywordsmedia.com/lead-manager/",
            target: "_blank"
          }
        },
        [
          _c("i", { staticClass: "far fa-question-circle" }),
          _vm._v(" User Guide ")
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c(
        "a",
        {
          attrs: {
            href: "https://www.markmywordsmedia.com/resources/",
            target: "_blank"
          }
        },
        [_c("i", { staticClass: "fas fa-coins" }), _vm._v(" Resources ")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }