var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ]
    },
    [
      (_vm.list && _vm.list.length) || _vm.loading
        ? _c("div", { staticClass: "table-wrapper" }, [
            _c("table", { staticClass: "table" }, [
              _vm._m(0),
              _vm.list
                ? _c(
                    "tbody",
                    _vm._l(_vm.list, function(item) {
                      return _c("tr", { key: item.id }, [
                        _c("td", [_vm._v(" " + _vm._s(item.date) + " ")]),
                        _c("td", [
                          _vm._v(
                            " " + _vm._s(item.user && item.user.username) + " "
                          )
                        ]),
                        _c("td", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.campaignOrTeamById(item.campaign_id)) +
                              " " +
                              _vm._s(_vm.campaignOrTeamById(item.team_id)) +
                              " "
                          )
                        ]),
                        _c("td", [
                          _vm._v(
                            " " +
                              _vm._s(item.assignee && item.assignee.username) +
                              " "
                          )
                        ]),
                        _c("td", { staticClass: "text-center" }, [
                          item.is_task
                            ? _c(
                                "div",
                                [
                                  _c("el-switch", {
                                    attrs: { "active-color": "#67C23A" },
                                    on: {
                                      change: function($event) {
                                        return _vm.toggleStatus(item.id)
                                      }
                                    },
                                    model: {
                                      value: item.is_done,
                                      callback: function($$v) {
                                        _vm.$set(item, "is_done", $$v)
                                      },
                                      expression: "item.is_done"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ]),
                        _c(
                          "td",
                          { staticClass: "note-cell" },
                          [
                            _c("nl2br", {
                              attrs: { tag: "p", text: item.note }
                            })
                          ],
                          1
                        ),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(" " + _vm._s(item.files.length || "-") + " ")
                        ]),
                        _c(
                          "td",
                          { staticClass: "fixed-right-column" },
                          [
                            _c(
                              "el-button-group",
                              [
                                _c("el-button", {
                                  attrs: {
                                    type: "primary",
                                    size: "mini",
                                    icon: "el-icon-edit",
                                    title: "Edit Campaign"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.onEdit(item)
                                    }
                                  }
                                }),
                                _c("el-button", {
                                  attrs: {
                                    type: "danger",
                                    size: "mini",
                                    icon: "el-icon-delete",
                                    title: "Archive Campaign"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.onDelete(item)
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ])
                    }),
                    0
                  )
                : _vm._e()
            ])
          ])
        : _c("EmptyList")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Date")]),
        _c("th", [_vm._v("User")]),
        _c("th", [_vm._v("Campaign/Team")]),
        _c("th", [_vm._v("Responsible admin")]),
        _c("th", { staticClass: "text-center" }, [_vm._v("Is done")]),
        _c("th", [_vm._v("Notes")]),
        _c("th", { staticClass: "text-center" }, [_vm._v("Files")]),
        _c("th", { staticClass: "fixed-right-column text-center" }, [
          _vm._v("Actions")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }