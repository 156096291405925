var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.summary
    ? _c("div", { staticClass: "summary-table-wrapper" }, [
        _c("table", { staticClass: "summary-table table" }, [
          _vm._m(0),
          _c("tbody", [
            _c("tr", [
              _c("td", [_vm._v("Total:")]),
              _c("td", [
                _vm._v(
                  _vm._s(
                    _vm.$_formatterMixin_formatCurrency(
                      _vm.summary.approved_ppc.total
                    )
                  )
                )
              ]),
              _c("td", [
                _vm._v(
                  _vm._s(
                    _vm.$_formatterMixin_formatCurrency(_vm.summary.net.total)
                  )
                )
              ]),
              _c("td", [_vm._v("-")]),
              _c("td", [
                _vm._v(
                  _vm._s(
                    _vm.$_formatterMixin_formatCurrency(
                      _vm.summary.service_fee.total
                    )
                  )
                )
              ])
            ]),
            _c("tr", [
              _c("td", [_vm._v("Average:")]),
              _c("td", [
                _vm._v(
                  _vm._s(
                    _vm.$_formatterMixin_formatCurrency(
                      _vm.summary.approved_ppc.avg
                    )
                  )
                )
              ]),
              _c("td", [_vm._v("-")]),
              _c("td", [
                _vm._v(
                  _vm._s(
                    _vm.$_formatterMixin_formatPercent(_vm.summary.ppc.avg)
                  )
                )
              ]),
              _c("td", [
                _vm._v(
                  _vm._s(
                    _vm.$_formatterMixin_formatCurrency(
                      _vm.summary.service_fee.avg
                    )
                  )
                )
              ])
            ])
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th"),
        _c("th", [_vm._v("Approved PPC")]),
        _c("th", [_vm._v("NET")]),
        _c("th", [_vm._v("PPC")]),
        _c("th", [_vm._v("Service fee")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }