var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ]
    },
    [
      (_vm.list && _vm.list.length) || _vm.loading
        ? _c("div", { staticClass: "table-wrapper" }, [
            _c("table", { staticClass: "table" }, [
              _c("thead", [
                _c("tr", [
                  _c(
                    "th",
                    [
                      _c("SortLabel", {
                        attrs: {
                          label: "URL",
                          order: _vm.$_sortingMixin_checkOrder("domain")
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.$_sortingMixin_doSort("domain")
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "th",
                    [
                      _c("SortLabel", {
                        attrs: {
                          label: "Redirect",
                          order: _vm.$_sortingMixin_checkOrder("redirect_url")
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.$_sortingMixin_doSort("redirect_url")
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "th",
                    { staticClass: "text-center" },
                    [
                      _c("SortLabel", {
                        attrs: {
                          label: "Status",
                          order: _vm.$_sortingMixin_checkOrder("live_status")
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.$_sortingMixin_doSort("live_status")
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "th",
                    [
                      _c("SortLabel", {
                        attrs: {
                          label: "Registrar",
                          order: _vm.$_sortingMixin_checkOrder("registrar")
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.$_sortingMixin_doSort("registrar")
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "th",
                    [
                      _c("SortLabel", {
                        attrs: {
                          label: "Registrant",
                          order: _vm.$_sortingMixin_checkOrder("registrant")
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.$_sortingMixin_doSort("registrant")
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "th",
                    { staticClass: "text-center" },
                    [
                      _c("SortLabel", {
                        attrs: {
                          label: "Registration",
                          order: _vm.$_sortingMixin_checkOrder("registration")
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.$_sortingMixin_doSort("registration")
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "th",
                    { staticClass: "text-center" },
                    [
                      _c("SortLabel", {
                        attrs: {
                          label: "Expiration",
                          order: _vm.$_sortingMixin_checkOrder("expiration")
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.$_sortingMixin_doSort("expiration")
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "th",
                    [
                      _c("SortLabel", {
                        attrs: {
                          label: "Nameserver 1",
                          order: _vm.$_sortingMixin_checkOrder("nameserver_1")
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.$_sortingMixin_doSort("nameserver_1")
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "th",
                    [
                      _c("SortLabel", {
                        attrs: {
                          label: "Nameserver 2",
                          order: _vm.$_sortingMixin_checkOrder("nameserver_2")
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.$_sortingMixin_doSort("nameserver_2")
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c("th", { staticClass: "fixed-right-column text-center" }, [
                    _vm._v(" Actions ")
                  ])
                ])
              ]),
              _vm.list
                ? _c(
                    "tbody",
                    _vm._l(_vm.list, function(domain) {
                      return _c("tr", { key: domain.id }, [
                        _c("td", [
                          domain.domain
                            ? _c(
                                "a",
                                {
                                  staticClass: "external-link",
                                  attrs: {
                                    href: "//" + domain.domain,
                                    target: "_blank"
                                  }
                                },
                                [
                                  _vm._v(" " + _vm._s(domain.domain) + " "),
                                  _c("i", {
                                    staticClass: "fas fa-external-link-alt"
                                  })
                                ]
                              )
                            : _vm._e()
                        ]),
                        _c("td", [
                          domain.redirect_url
                            ? _c(
                                "a",
                                {
                                  staticClass: "external-link",
                                  attrs: {
                                    href: "" + domain.redirect_url,
                                    target: "_blank"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(domain.redirect_url) + " "
                                  ),
                                  _c("i", {
                                    staticClass: "fas fa-external-link-alt"
                                  })
                                ]
                              )
                            : _vm._e()
                        ]),
                        _c("td", { staticClass: "text-center" }, [
                          _c(
                            "div",
                            {
                              class: +domain.live_status
                                ? "text-success"
                                : "text-danger"
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.getSiteStatusById(domain.live_status)
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ]),
                        _c("td", [_vm._v(_vm._s(domain.registrar))]),
                        _c("td", [_vm._v(_vm._s(domain.registrant))]),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(_vm._s(domain.registration))
                        ]),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(_vm._s(domain.expiration))
                        ]),
                        _c("td", { staticClass: "cell-ns" }, [
                          _vm._v(_vm._s(domain.nameserver_1))
                        ]),
                        _c("td", { staticClass: "cell-ns" }, [
                          _vm._v(_vm._s(domain.nameserver_2))
                        ]),
                        _c(
                          "td",
                          {
                            staticClass:
                              "fixed-right-column table__action-column"
                          },
                          [
                            _c(
                              "el-button-group",
                              [
                                _c("el-button", {
                                  attrs: {
                                    type: "danger",
                                    size: "mini",
                                    title: "Delete",
                                    icon: "el-icon-delete"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.handleDelete(domain)
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ])
                    }),
                    0
                  )
                : _vm._e()
            ])
          ])
        : _c("EmptyList")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }