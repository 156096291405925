var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "file-uploader" },
    [
      _c(
        "el-upload",
        {
          attrs: {
            action: "" + _vm.baseApi + _vm.action,
            headers: _vm.headers,
            "on-success": _vm.handleSuccess,
            accept: "image/jpeg, image/png",
            multiple: "",
            drag: ""
          }
        },
        [
          _c("div", { staticClass: "file-uploader__text" }, [
            _c("i", { staticClass: "el-icon-upload" }),
            _c("div", { staticClass: "el-upload__text" }, [
              _vm._v("Drop file here or "),
              _c("em", [_vm._v("click to upload")]),
              _c("br"),
              _vm._v("Allowed jpg/jpeg/png files")
            ])
          ])
        ]
      ),
      _vm._l(_vm.files, function(file) {
        return _c(
          "div",
          {
            key: file.id + "ww",
            staticClass: "file-uploader__img",
            style:
              "background-image:url(" +
              _vm.$_imageMixin_getImagePath(file.url) +
              ");"
          },
          [
            _c("div", { staticClass: "file-uploader__img-actions" }, [
              _c(
                "div",
                {
                  staticClass: "file-uploader__img-action",
                  on: {
                    click: function($event) {
                      _vm.zoomImage(_vm.$_imageMixin_getImagePath(file.url))
                    }
                  }
                },
                [_c("i", { staticClass: "el-icon-zoom-in" })]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "file-uploader__img-action file-uploader__img-action--delete",
                  on: {
                    click: function($event) {
                      return _vm.handleDelete(file.id)
                    }
                  }
                },
                [_c("i", { staticClass: "el-icon-delete" })]
              )
            ])
          ]
        )
      }),
      _c("div", { staticClass: "hidden" }, [
        _vm._v(" " + _vm._s(_vm.files) + " ")
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.imageZoomModalVisible,
            "before-close": _vm.zoomImageClose,
            "append-to-body": ""
          },
          on: {
            "update:visible": function($event) {
              _vm.imageZoomModalVisible = $event
            }
          }
        },
        [
          _c("img", {
            staticClass: "file-uploader__image-zoom",
            attrs: { src: _vm.zoomedImg }
          })
        ]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }