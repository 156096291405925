var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      ref: "loading-wrapper"
    },
    [
      (_vm.list && _vm.list.length) || _vm.loading
        ? _c(
            "div",
            {
              ref: "table-wrapper",
              staticClass: "table-wrapper",
              class: {
                "table-wrapper--related-leads": _vm.isRelatedLeads,
                "table-wrapper--base-related-lead": _vm.isBaseRelatedLead,
                "table-wrapper--global-related-leads": _vm.isGlobalRelatedLeads
              }
            },
            [
              _c("table", { staticClass: "table", class: {} }, [
                _c("thead", [
                  _c("tr", [
                    _vm.isColumnVisible("campaign")
                      ? _c(
                          "th",
                          [
                            _c("SortLabel", {
                              attrs: {
                                order: _vm.$_sortingMixin_checkOrder(
                                  "campaign.name"
                                ),
                                label: "Campaign"
                              },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.$_sortingMixin_doSort(
                                    "campaign.name"
                                  )
                                }
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.isColumnVisible("lead_date")
                      ? _c(
                          "th",
                          { staticClass: "text-center" },
                          [
                            _c("SortLabel", {
                              attrs: {
                                order: _vm.$_sortingMixin_checkOrder(
                                  "lead_date"
                                ),
                                label: "Date"
                              },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.$_sortingMixin_doSort("lead_date")
                                }
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.isColumnVisible("lead_type")
                      ? _c(
                          "th",
                          { staticClass: "text-center" },
                          [
                            _c("SortLabel", {
                              attrs: {
                                order: _vm.$_sortingMixin_checkOrder(
                                  "lead_type"
                                ),
                                label: "Type"
                              },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.$_sortingMixin_doSort("lead_type")
                                }
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.isColumnVisible("tracking_phone_number") &&
                    _vm.isAdminOrSuperAdmin
                      ? _c("th", [_vm._v(" Tracking Phone ")])
                      : _vm._e(),
                    _vm.isColumnVisible("tracking_source") &&
                    _vm.isAdminOrSuperAdmin
                      ? _c("th", [_vm._v(" Tracking Source ")])
                      : _vm._e(),
                    _vm.isColumnVisible("lead_name")
                      ? _c(
                          "th",
                          [
                            _c("SortLabel", {
                              attrs: {
                                order: _vm.$_sortingMixin_checkOrder(
                                  "lead_name"
                                ),
                                label: "Name"
                              },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.$_sortingMixin_doSort("lead_name")
                                }
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.isColumnVisible("lead_location")
                      ? _c(
                          "th",
                          [
                            _c("SortLabel", {
                              attrs: {
                                order: _vm.$_sortingMixin_checkOrder(
                                  "lead_location"
                                ),
                                label: "Location"
                              },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.$_sortingMixin_doSort(
                                    "lead_location"
                                  )
                                }
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.isColumnVisible("lead_email")
                      ? _c(
                          "th",
                          [
                            _c("SortLabel", {
                              attrs: {
                                order: _vm.$_sortingMixin_checkOrder(
                                  "lead_email"
                                ),
                                label: "Email"
                              },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.$_sortingMixin_doSort("lead_email")
                                }
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.isColumnVisible("lead_phone")
                      ? _c(
                          "th",
                          [
                            _c("SortLabel", {
                              attrs: {
                                order: _vm.$_sortingMixin_checkOrder(
                                  "lead_phone"
                                ),
                                label: "Phone"
                              },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.$_sortingMixin_doSort("lead_phone")
                                }
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.isColumnVisible("message")
                      ? _c(
                          "th",
                          [
                            _c("SortLabel", {
                              attrs: {
                                order: _vm.$_sortingMixin_checkOrder(
                                  "duration"
                                ),
                                label: "Message"
                              },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.$_sortingMixin_doSort("duration")
                                }
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.isColumnVisible("related") && !_vm.isRelatedLeads
                      ? _c("th", [_vm._v("Related")])
                      : _vm._e(),
                    _vm.isColumnVisible("notes")
                      ? _c("th", [_vm._v("Notes")])
                      : _vm._e(),
                    _vm.isColumnVisible("tags") &&
                    _vm.user.permissions.show_lead_http_columns
                      ? _c("th", [_vm._v(" Tags ")])
                      : _vm._e(),
                    _vm.isColumnVisible("confirmed")
                      ? _c(
                          "th",
                          [
                            _c("SortLabel", {
                              attrs: {
                                order: _vm.$_sortingMixin_checkOrder(
                                  "confirmed"
                                ),
                                label: "Verified"
                              },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.$_sortingMixin_doSort("confirmed")
                                }
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.isColumnVisible("changers") &&
                    _vm.isAdminOrSuperAdmin &&
                    _vm.showLeadHttpColumns
                      ? _c("th", [_vm._v("Changers")])
                      : _vm._e(),
                    _vm.isColumnVisible("client_progress")
                      ? _c(
                          "th",
                          [
                            _c("SortLabel", {
                              attrs: {
                                order: _vm.$_sortingMixin_checkOrder(
                                  "client_progress"
                                ),
                                label: "Progress"
                              },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.$_sortingMixin_doSort(
                                    "client_progress"
                                  )
                                }
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.isColumnVisible("company_name")
                      ? _c("th", [_vm._v("Company Name")])
                      : _vm._e(),
                    _vm.isColumnVisible("client_notes")
                      ? _c("th", [_vm._v("Client Notes")])
                      : _vm._e(),
                    _vm.isColumnVisible("client_value")
                      ? _c(
                          "th",
                          [
                            _c("SortLabel", {
                              attrs: {
                                order: _vm.$_sortingMixin_checkOrder(
                                  "client_value"
                                ),
                                label: "Value"
                              },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.$_sortingMixin_doSort(
                                    "client_value"
                                  )
                                }
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.isColumnVisible("client_profit")
                      ? _c(
                          "th",
                          [
                            _c("SortLabel", {
                              attrs: {
                                order: _vm.$_sortingMixin_checkOrder(
                                  "client_profit"
                                ),
                                label: "Profit"
                              },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.$_sortingMixin_doSort(
                                    "client_profit"
                                  )
                                }
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.isColumnVisible("rating")
                      ? _c(
                          "th",
                          [
                            _c("SortLabel", {
                              attrs: {
                                order: _vm.$_sortingMixin_checkOrder("rating"),
                                label: "Rating"
                              },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.$_sortingMixin_doSort("rating")
                                }
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.isColumnVisible("url") &&
                    _vm.isAdminOrSuperAdmin &&
                    _vm.showLeadHttpColumns
                      ? _c("th", [_vm._v("URL")])
                      : _vm._e(),
                    _vm.isColumnVisible("referrer") &&
                    _vm.isAdminOrSuperAdmin &&
                    _vm.showLeadHttpColumns
                      ? _c("th", [_vm._v("Referrer")])
                      : _vm._e(),
                    _vm.isColumnVisible("source") &&
                    _vm.isAdminOrSuperAdmin &&
                    _vm.showLeadHttpColumns
                      ? _c(
                          "th",
                          [
                            _c("SortLabel", {
                              attrs: {
                                order: _vm.$_sortingMixin_checkOrder("source"),
                                label: "Source"
                              },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.$_sortingMixin_doSort("source")
                                }
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.isColumnVisible("keywords") &&
                    _vm.isAdminOrSuperAdmin &&
                    _vm.showLeadHttpColumns
                      ? _c("th", [_vm._v("Keyword")])
                      : _vm._e(),
                    _vm.isColumnVisible("gclid") &&
                    _vm.isAdminOrSuperAdmin &&
                    _vm.showLeadHttpColumns
                      ? _c("th", [_vm._v("gclid")])
                      : _vm._e(),
                    _vm.isColumnVisible("hide") && _vm.isAdminOrSuperAdmin
                      ? _c("th", [_vm._v("Is Hidden")])
                      : _vm._e(),
                    _vm.isColumnVisible("action")
                      ? _c("th", { staticClass: "text-center" }, [
                          _vm._v(" Actions ")
                        ])
                      : _vm._e()
                  ])
                ]),
                _vm.list
                  ? _c(
                      "tbody",
                      _vm._l(_vm.list, function(lead) {
                        return _c("LeadsListItem", {
                          key: lead.id,
                          attrs: {
                            "is-related-leads": _vm.isRelatedLeads,
                            lead: lead
                          },
                          on: {
                            filter: function(obj) {
                              return _vm.$emit("filter", obj)
                            },
                            "open-related-leads-dialog": function($event) {
                              return _vm.$emit("open-related-leads-dialog")
                            },
                            "refetch-data": function($event) {
                              return _vm.$emit("refetch-data")
                            }
                          }
                        })
                      }),
                      1
                    )
                  : _vm._e()
              ])
            ]
          )
        : _c("EmptyList")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }