var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ]
    },
    [
      _vm.admins || _vm.loading
        ? _c("div", { staticClass: "table-wrapper" }, [
            _c("table", { staticClass: "table" }, [
              _vm._m(0),
              _vm.admins
                ? _c(
                    "tbody",
                    _vm._l(_vm.admins, function(admin) {
                      return _c("tr", { key: admin.id }, [
                        _c("td", [_vm._v(" " + _vm._s(admin.username) + " ")]),
                        _c("td", [_vm._v(" " + _vm._s(admin.email) + " ")]),
                        _c("td", [
                          _vm._v(" " + _vm._s(admin.campaigns_count) + " ")
                        ]),
                        _c(
                          "td",
                          { staticClass: "fixed-right-column" },
                          [
                            _c(
                              "el-button-group",
                              [
                                _c("el-button", {
                                  attrs: {
                                    type: "primary",
                                    size: "mini",
                                    icon: "el-icon-edit",
                                    title: "Edit"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.onEdit(admin)
                                    }
                                  }
                                }),
                                _c("el-button", {
                                  attrs: {
                                    type: "danger",
                                    size: "mini",
                                    title: "Delete",
                                    icon: "el-icon-delete"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.onDelete(admin)
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ])
                    }),
                    0
                  )
                : _vm._e()
            ])
          ])
        : _c("EmptyList")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Name")]),
        _c("th", [_vm._v("Email")]),
        _c("th", [_vm._v("Assigned Campaigns")]),
        _c("th", { staticClass: "fixed-right-column text-center" }, [
          _vm._v("Actions")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }