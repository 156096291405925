var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "changelog-table-outer"
    },
    [
      _vm.changelog || _vm.loading
        ? _c("div", { staticClass: "table-wrapper" }, [
            _vm.campaignsAndTeamsList
              ? _c("table", { staticClass: "changelog-table table" }, [
                  _c("thead", [
                    _c(
                      "tr",
                      [
                        _c(
                          "th",
                          {
                            staticClass:
                              "fixed-left-column changelog-table__first-th"
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "changelog-table__total-wrapper" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "changelog-table__badge changelog-table__badge--total",
                                    class: {
                                      "changelog-table__badge--accent":
                                        _vm.changelog.total_changelog_count > 0
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.changelog.total_changelog_count
                                        ) +
                                        " "
                                    )
                                  ]
                                ),
                                _vm._v(" Campaign ")
                              ]
                            )
                          ]
                        ),
                        _vm._l(_vm.changelog.days_list, function(data, date) {
                          return _c("th", { key: date }, [
                            _c(
                              "div",
                              { staticClass: "changelog-table__date-wrapper" },
                              [
                                _vm._v(" " + _vm._s(date) + " "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "changelog-table__badge",
                                    class: {
                                      "changelog-table__badge--accent":
                                        data.total > 0
                                    }
                                  },
                                  [_vm._v(" " + _vm._s(data.total) + " ")]
                                )
                              ]
                            )
                          ])
                        })
                      ],
                      2
                    )
                  ]),
                  _c(
                    "tbody",
                    _vm._l(_vm.campaignsAndTeamsList, function(campaign) {
                      return _c(
                        "tr",
                        { key: campaign.id },
                        [
                          _c(
                            "td",
                            {
                              staticClass:
                                "fixed-left-column changelog-table__first-td"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "changelog-table__campaign-name"
                                },
                                [_vm._v(" " + _vm._s(campaign.name) + " ")]
                              ),
                              _c("div", [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "changelog-table__campaign-note"
                                  },
                                  [_vm._v(" " + _vm._s(campaign.note) + " ")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "changelog-table__button changelog-table__button--primary",
                                    on: {
                                      click: function($event) {
                                        return _vm.editCampaignNote(campaign)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          campaign.note
                                            ? "Edit Note"
                                            : "Add Note"
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ])
                            ]
                          ),
                          _vm._l(
                            _vm.changelog.changelogs_by_campaign[campaign.id]
                              .data,
                            function(data, date) {
                              return _c("td", { key: date }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "changelog-table__cell-header"
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "changelog-table__notes-label"
                                      },
                                      [
                                        _vm._v(
                                          " Notes: " + _vm._s(data.total) + " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "changelog-table__button",
                                        on: {
                                          click: function($event) {
                                            return _vm.addNote(
                                              campaign.id,
                                              date
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(" Add Note ")]
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "changelog-table__cell-body" },
                                  [
                                    data.data
                                      ? _c(
                                          "div",
                                          _vm._l(data.data, function(item) {
                                            return _c(
                                              "div",
                                              {
                                                key: item.id,
                                                staticClass:
                                                  "changelog-table__note",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.editNote(item)
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "changelog-table__note-heading"
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "changelog-table__note-author"
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "changelog-table__note-img fas fa-user-circle"
                                                        }),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              item.user &&
                                                                item.user
                                                                  .username
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "changelog-table__note-date"
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "far fa-clock"
                                                        }),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.formatTime(
                                                                item.created_at
                                                              )
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _c("nl2br", {
                                                  attrs: {
                                                    tag: "div",
                                                    text: item.note
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          }),
                                          0
                                        )
                                      : _vm._e()
                                  ]
                                )
                              ])
                            }
                          )
                        ],
                        2
                      )
                    }),
                    0
                  )
                ])
              : _vm._e()
          ])
        : _c("EmptyList")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }