var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "page-title-group" },
        [
          _c("h1", [_vm._v("Changelog")]),
          _c(
            "el-button",
            {
              attrs: { type: "accent", icon: "el-icon-plus" },
              on: { click: _vm.openAddEditDialog }
            },
            [_vm._v(" Create New Item ")]
          )
        ],
        1
      ),
      _c(
        "FilterPanel",
        [
          _c("ActivityLogFilters", {
            attrs: { defaultDataValues: _vm.defaultDataValues },
            on: { filter: _vm.doFilter }
          })
        ],
        1
      ),
      _c(
        "MainPanel",
        [
          _c("ChangelogTable", {
            attrs: { loading: _vm.loading },
            on: {
              "edit-campaign-note": _vm.openEditCampaignNoteDialog,
              "add-note": _vm.openAddEditDialog,
              "edit-note": _vm.openAddEditDialog
            }
          })
        ],
        1
      ),
      _c("AddEditItemDialog", {
        attrs: { visible: _vm.addEditDialogVisible },
        on: {
          "update:visible": function($event) {
            _vm.addEditDialogVisible = $event
          },
          close: _vm.closeAddEditDialog,
          "refetch-data": _vm.getChangelog
        }
      }),
      _c("EditCampaignNoteDialog", {
        attrs: { visible: _vm.editCampaignNoteDialogVisible },
        on: {
          "update:visible": function($event) {
            _vm.editCampaignNoteDialogVisible = $event
          },
          close: _vm.closeEditCampaignNoteDialog
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }