var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.leads && _vm.leads.total_calc
      ? _c(
          "div",
          { staticClass: "campaign-trouble-states-totals" },
          _vm._l(_vm.troubleStatesForTotals, function(state) {
            return _c(
              "span",
              {
                key: state.key,
                class: [_vm.campaignTroubleStateTotalClass(state.key)]
              },
              [
                _vm._v(
                  " " +
                    _vm._s(state.label) +
                    ": " +
                    _vm._s(
                      _vm.leads.total_calc.trouble_states_counters[state.key]
                    ) +
                    " "
                ),
                _vm.leads.total_calc.trouble_states_percentages[state.key]
                  ? _c("span", [
                      _vm._v(
                        " (" +
                          _vm._s(
                            _vm.leads.total_calc.trouble_states_percentages[
                              state.key
                            ]
                          ) +
                          "%) "
                      )
                    ])
                  : _vm._e()
              ]
            )
          }),
          0
        )
      : _vm._e(),
    _c("div", { staticClass: "main-wrapper" }, [
      _c("div", { staticClass: "shadow" }),
      _c(
        "div",
        {
          ref: "leftTableWrapper",
          staticClass: "table-left-wrapper",
          on: { scroll: _vm.onLeftSideScroll }
        },
        [
          _c("table", { staticClass: "table-left" }, [
            _c("thead", { staticClass: "table-left__thead" }, [
              _vm._m(0),
              _vm.leads && _vm.leads.total_calc
                ? _c("tr", { staticClass: "second-tr-row" }, [
                    _c("th"),
                    _c("th", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.leads.total_calc.unique_clients_count) +
                          " "
                      )
                    ]),
                    _c("th", [
                      _vm._v(
                        " " + _vm._s(_vm.leads.total_calc.ppc_count) + " "
                      ),
                      _c("br"),
                      _vm._v(
                        " " + _vm._s(_vm.leads.total_calc.ppc_percentage) + "% "
                      )
                    ]),
                    _c("th"),
                    _c("th", [
                      _vm._v(
                        " " + _vm._s(_vm.leads.total_calc.target_average) + " "
                      )
                    ]),
                    _c("th", { staticClass: "color-success" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.leads.total_calc.verified_leads_count) +
                          " "
                      )
                    ]),
                    _c("th", { staticClass: "color-danger" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.leads.total_calc.missed_leads_count) +
                          " "
                      )
                    ]),
                    _c("th"),
                    _c("th", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.leads.total_calc.run_rate_average) +
                          " "
                      )
                    ])
                  ])
                : _vm._e(),
              _c("tr", { staticClass: "third-tr-row" }, [
                _c(
                  "th",
                  { staticClass: "sort" },
                  [
                    _c("SortLabel", {
                      attrs: {
                        order: _vm.$_sortingMixin_checkOrder("campaign"),
                        label: "Campaign"
                      },
                      nativeOn: {
                        click: function($event) {
                          return _vm.$_sortingMixin_doSort("campaign")
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "th",
                  { staticClass: "sort" },
                  [
                    _c("SortLabel", {
                      attrs: {
                        order: _vm.$_sortingMixin_checkOrder("client"),
                        label: "Client"
                      },
                      nativeOn: {
                        click: function($event) {
                          return _vm.$_sortingMixin_doSort("client")
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "th",
                  { staticClass: "sort" },
                  [
                    _c("SortLabel", {
                      attrs: {
                        order: _vm.$_sortingMixin_checkOrder("ppc"),
                        label: "PPC"
                      },
                      nativeOn: {
                        click: function($event) {
                          return _vm.$_sortingMixin_doSort("ppc")
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "th",
                  { staticClass: "sort" },
                  [
                    _c("SortLabel", {
                      attrs: {
                        order: _vm.$_sortingMixin_checkOrder("trouble_state"),
                        label: "Status"
                      },
                      nativeOn: {
                        click: function($event) {
                          return _vm.$_sortingMixin_doSort("trouble_state")
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "th",
                  { staticClass: "sort" },
                  [
                    _c("SortLabel", {
                      attrs: {
                        order: _vm.$_sortingMixin_checkOrder("target"),
                        label: "Target"
                      },
                      nativeOn: {
                        click: function($event) {
                          return _vm.$_sortingMixin_doSort("target")
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "th",
                  { staticClass: "sort" },
                  [
                    _c("SortLabel", {
                      attrs: {
                        order: _vm.$_sortingMixin_checkOrder("verified"),
                        label: "Verif."
                      },
                      nativeOn: {
                        click: function($event) {
                          return _vm.$_sortingMixin_doSort("verified")
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "th",
                  { staticClass: "sort" },
                  [
                    _c("SortLabel", {
                      attrs: {
                        order: _vm.$_sortingMixin_checkOrder("missed"),
                        label: "Miss."
                      },
                      nativeOn: {
                        click: function($event) {
                          return _vm.$_sortingMixin_doSort("missed")
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "th",
                  { staticClass: "sort" },
                  [
                    _c("SortLabel", {
                      attrs: {
                        order: _vm.$_sortingMixin_checkOrder("days_left"),
                        label: "Days Left"
                      },
                      nativeOn: {
                        click: function($event) {
                          return _vm.$_sortingMixin_doSort("days_left")
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "th",
                  { staticClass: "sort" },
                  [
                    _c("SortLabel", {
                      attrs: {
                        order: _vm.$_sortingMixin_checkOrder("run_date"),
                        label: "Run Rate"
                      },
                      nativeOn: {
                        click: function($event) {
                          return _vm.$_sortingMixin_doSort("run_date")
                        }
                      }
                    })
                  ],
                  1
                )
              ])
            ]),
            _c(
              "tbody",
              _vm._l(_vm.leads && _vm.leads.campaigns, function(campaign, key) {
                return _c(
                  "tr",
                  {
                    key: key,
                    class: [
                      _vm.campaignTroubleStateClass(campaign.trouble_state)
                    ]
                  },
                  [
                    _c(
                      "td",
                      {
                        staticClass: "campaign-cell",
                        attrs: { title: campaign.name }
                      },
                      [_vm._v(" " + _vm._s(campaign.name) + " ")]
                    ),
                    _c("td", { staticClass: "client-cell" }, [
                      _vm._v(" " + _vm._s(campaign.client) + " ")
                    ]),
                    _c(
                      "td",
                      { staticClass: "ppc-cell" },
                      [
                        _c("el-switch", {
                          attrs: { "active-value": 1, "inactive-value": 0 },
                          on: {
                            change: function($event) {
                              return _vm.switchPPC(campaign)
                            }
                          },
                          model: {
                            value: campaign.is_ppc,
                            callback: function($$v) {
                              _vm.$set(campaign, "is_ppc", $$v)
                            },
                            expression: "campaign.is_ppc"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "td",
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "Select", size: "mini" },
                            model: {
                              value: campaign.trouble_state,
                              callback: function($$v) {
                                _vm.$set(campaign, "trouble_state", $$v)
                              },
                              expression: "campaign.trouble_state"
                            }
                          },
                          _vm._l(_vm.troubleStates, function(state) {
                            return _c("el-option", {
                              key: state.key,
                              attrs: { label: state.label, value: state.key },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.updateTroubleState(
                                    campaign.id,
                                    state.key
                                  )
                                }
                              }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _c("td", { staticClass: "target-cell text-center" }, [
                      _vm._v(" " + _vm._s(campaign.leads_target) + " ")
                    ]),
                    _c(
                      "td",
                      { staticClass: "current-leads-cell text-center" },
                      [
                        _c("span", { staticClass: "color-success" }, [
                          _vm._v(_vm._s(campaign.current_leads))
                        ])
                      ]
                    ),
                    _c("td", { staticClass: "text-center" }, [
                      _c("span", { staticClass: "color-danger" }, [
                        _vm._v(_vm._s(campaign.missed_leads))
                      ])
                    ]),
                    _c("td", { staticClass: "text-center" }, [
                      _vm._v(_vm._s(campaign.days_left))
                    ]),
                    _c(
                      "td",
                      {
                        staticClass: "text-center",
                        class: {
                          "color-danger":
                            campaign.run_rate < 1 &&
                            campaign.leads_target != 0 &&
                            campaign.days_left <= 22
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              campaign.leads_target == 0
                                ? ""
                                : campaign.run_rate
                            ) +
                            " "
                        )
                      ]
                    )
                  ]
                )
              }),
              0
            )
          ])
        ]
      ),
      _vm.leads
        ? _c(
            "div",
            {
              ref: "rightTableWrapper",
              staticClass: "table-right-wrapper",
              on: { scroll: _vm.onRightSideScroll }
            },
            [
              _c("table", { staticClass: "table-right" }, [
                _c("thead", { staticClass: "table-right__thead" }, [
                  _c(
                    "tr",
                    { staticClass: "first-tr-row" },
                    _vm._l(_vm.leads.weeks, function(month, index) {
                      return _c(
                        "th",
                        {
                          key: "" + month.month + month.year + index,
                          staticClass: "month-name-cell",
                          attrs: { colspan: month.count + 1 }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.getMonthName(month.month)) +
                              " (" +
                              _vm._s(
                                _vm.leads.totals[index].weeks.total
                                  .avg_confirmed_for_campaign
                              ) +
                              ") "
                          )
                        ]
                      )
                    }),
                    0
                  ),
                  _c(
                    "tr",
                    { staticClass: "second-tr-row" },
                    [
                      _vm._l(_vm.leads.totals, function(total) {
                        return _vm._l(_vm.sortWeeks(total.weeks), function(
                          week,
                          key
                        ) {
                          return _c(
                            "th",
                            {
                              key: "" + key + total.month + total.year + key,
                              class: {
                                "total-cell total-cell--divider":
                                  key === "total"
                              }
                            },
                            [
                              _c("span", { staticClass: "color-success" }, [
                                _vm._v(
                                  _vm._s(
                                    week.confirmed != 0 ? week.confirmed : null
                                  )
                                )
                              ]),
                              _c("br"),
                              _c("span", { staticClass: "color-danger" }, [
                                _vm._v(
                                  _vm._s(week.missed != 0 ? week.missed : null)
                                )
                              ])
                            ]
                          )
                        })
                      })
                    ],
                    2
                  ),
                  _c(
                    "tr",
                    { staticClass: "third-tr-row" },
                    [
                      _vm._l(_vm.leads.weeks, function(weeks, index) {
                        return [
                          _c(
                            "th",
                            {
                              key:
                                "" + index + weeks.year + weeks.month + index,
                              staticClass: "total-cell total-cell--divider"
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "label-wrapper" },
                                [
                                  _c("SortLabel", {
                                    attrs: {
                                      order: _vm.$_sortingMixin_checkOrder(
                                        "total." +
                                          weeks.month +
                                          "-" +
                                          weeks.year
                                      ),
                                      label: "Total"
                                    },
                                    nativeOn: {
                                      click: function($event) {
                                        return _vm.$_sortingMixin_doSort(
                                          "total." +
                                            weeks.month +
                                            "-" +
                                            weeks.year
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          ),
                          _vm._l(_vm.getRange(weeks.count), function(week) {
                            return _c("th", { key: "" + week + index + week }, [
                              _c(
                                "div",
                                { staticClass: "label-wrapper" },
                                [
                                  _c("SortLabel", {
                                    attrs: {
                                      label: "w" + week,
                                      order: _vm.$_sortingMixin_checkOrder(
                                        "w" +
                                          week +
                                          "." +
                                          weeks.month +
                                          "-" +
                                          weeks.year
                                      )
                                    },
                                    nativeOn: {
                                      click: function($event) {
                                        return _vm.$_sortingMixin_doSort(
                                          "w" +
                                            week +
                                            "." +
                                            weeks.month +
                                            "-" +
                                            weeks.year
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ])
                          })
                        ]
                      })
                    ],
                    2
                  )
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.leads && _vm.leads.campaigns, function(
                    campaign,
                    key
                  ) {
                    return _c(
                      "tr",
                      { key: "" + campaign.id + key },
                      [
                        _vm._l(campaign.leads, function(leads, index) {
                          return _vm._l(_vm.sortWeeks(leads.weeks), function(
                            count,
                            key
                          ) {
                            return _c(
                              "td",
                              {
                                key: "" + key + index,
                                staticClass: "lead-cell",
                                class: { "lead-cell--divider": key === 0 }
                              },
                              [
                                _c("span", { staticClass: "color-success" }, [
                                  _vm._v(
                                    _vm._s(
                                      count.confirmed != 0
                                        ? count.confirmed
                                        : " "
                                    )
                                  )
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      count.confirmed != 0 && count.missed != 0
                                        ? "/"
                                        : ""
                                    ) +
                                    " "
                                ),
                                _c("span", { staticClass: "color-danger" }, [
                                  _vm._v(
                                    _vm._s(
                                      count.missed != 0 ? count.missed : " "
                                    )
                                  )
                                ])
                              ]
                            )
                          })
                        })
                      ],
                      2
                    )
                  }),
                  0
                )
              ])
            ]
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "first-tr-row" }, [
      _c("th", { attrs: { colspan: "9" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }