var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._m(0),
      _c(
        "FilterPanel",
        [_c("GmbsMapFilters", { on: { filter: _vm.doFilter } })],
        1
      ),
      _c("GmbsMap", {
        ref: "map",
        attrs: { "current-category": _vm.category },
        on: { "refetch-data": _vm.getGmbsMap }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-title-group" }, [
      _c("h1", [_vm._v("Campaign Map")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }