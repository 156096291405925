import { render, staticRenderFns } from "./Chart.vue?vue&type=template&id=25b2df66&scoped=true&"
import script from "./Chart.vue?vue&type=script&lang=js&"
export * from "./Chart.vue?vue&type=script&lang=js&"
import style0 from "./Chart.vue?vue&type=style&index=0&id=25b2df66&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25b2df66",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/source/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('25b2df66')) {
      api.createRecord('25b2df66', component.options)
    } else {
      api.reload('25b2df66', component.options)
    }
    module.hot.accept("./Chart.vue?vue&type=template&id=25b2df66&scoped=true&", function () {
      api.rerender('25b2df66', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/leads/Chart.vue"
export default component.exports