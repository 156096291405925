var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    { ref: "form", attrs: { model: _vm.form, "label-position": "top" } },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "before-close": _vm.handleClose,
            title: "Edit Blacklist",
            visible: _vm.visible,
            width: "94%"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "keywords" } },
            [
              _c("el-input", {
                attrs: { type: "textarea", rows: "12" },
                model: {
                  value: _vm.form.keywords,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "keywords", $$v)
                  },
                  expression: "form.keywords"
                }
              })
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("Cancel")
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                [_vm._v("Save")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }