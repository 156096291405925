var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "before-close": _vm.handleClose,
        visible: _vm.visible,
        bottom: "0",
        title: "",
        top: "30px",
        width: "96%"
      },
      on: {
        opened: function($event) {
          return _vm.getRelatedLeads()
        }
      }
    },
    [
      _c("LeadsList", {
        attrs: {
          list: _vm.baseLead && [_vm.baseLead],
          loading: _vm.loading,
          "is-base-related-lead": ""
        },
        on: {
          sort: _vm.doSort,
          "refetch-data": function($event) {
            return _vm.getRelatedLeads(true)
          }
        }
      }),
      _c("div", { staticClass: "icons-container" }, [
        _c("i", { staticClass: "fas fa-arrow-down" }),
        _vm._v(" Related Leads "),
        _c("i", { staticClass: "fas fa-arrow-down" })
      ]),
      _c(
        "div",
        { staticClass: "action-bar" },
        [
          _c("PageLimitSwitcher", {
            attrs: { active: _vm.filterObject.per_page },
            on: { click: _vm.setPageLimit }
          }),
          _vm.leads
            ? _c("el-pagination", {
                attrs: {
                  "current-page": _vm.filterObject.page,
                  "page-size": _vm.filterObject.per_page,
                  total: _vm.leads.meta.total,
                  background: "",
                  "hide-on-single-page": "",
                  layout: "prev, pager, next"
                },
                on: { "current-change": _vm.changePage }
              })
            : _vm._e(),
          _c("div", { staticClass: "table-total" }, [
            _vm._v(
              " Total Items: " +
                _vm._s((_vm.leads && _vm.leads.meta.total) || "-") +
                " "
            )
          ])
        ],
        1
      ),
      _c("LeadsList", {
        attrs: { list: _vm.list, loading: _vm.loading, "is-related-leads": "" },
        on: {
          sort: _vm.doSort,
          "refetch-data": function($event) {
            return _vm.getRelatedLeads(true)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }