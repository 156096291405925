var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.visible,
            "before-close": _vm.closeModal,
            fullscreen: "",
            title: "Edit Campaign Area"
          }
        },
        [
          _c("div", { ref: "map", staticClass: "map" }),
          _c("GeoObjectsList", { attrs: { geoObjects: _vm.geoObjects } }),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.closeModal } }, [
                _vm._v("Cancel")
              ]),
              _vm.isGeoObjectsExist
                ? _c(
                    "el-button",
                    { attrs: { type: "danger" }, on: { click: _vm.onDelete } },
                    [_vm._v("Remove")]
                  )
                : _vm._e(),
              _vm.isGeoObjectsExist
                ? _c(
                    "el-button",
                    { attrs: { type: "accent" }, on: { click: _vm.onSubmit } },
                    [_vm._v("Save")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }