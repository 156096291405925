var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._m(0),
      _c(
        "FilterPanel",
        [_c("IPsFilters", { on: { filter: _vm.doFilter } })],
        1
      ),
      _c(
        "MainPanel",
        [
          _c(
            "div",
            { staticClass: "action-bar" },
            [
              _c("PageLimitSwitcher", {
                attrs: { active: _vm.filterObject.per_page },
                on: { click: _vm.setPageLimit }
              }),
              _vm.ipsList
                ? _c("el-pagination", {
                    attrs: {
                      background: "",
                      layout: "prev, pager, next",
                      "page-size": _vm.filterObject.per_page,
                      "current-page": _vm.filterObject.page,
                      total: _vm.ipsList.total,
                      "hide-on-single-page": ""
                    },
                    on: { "current-change": _vm.changePage }
                  })
                : _vm._e(),
              _c("div", { staticClass: "table-total" }, [
                _vm._v(
                  " Total Items: " +
                    _vm._s((_vm.ipsList && _vm.ipsList.total) || "-") +
                    " "
                )
              ])
            ],
            1
          ),
          _c("IPsList", {
            attrs: {
              loading: _vm.loading,
              list: _vm.ipsList && _vm.ipsList.data
            },
            on: { sort: _vm.doSort }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-title-group" }, [
      _c("h1", [_vm._v("IP Addresses")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }