var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.visible,
        title: "Select Table Columns",
        width: "94%"
      },
      on: { close: _vm.closeModal }
    },
    [
      _c(
        "div",
        { staticClass: "columns-selector" },
        _vm._l(_vm.columns, function(column) {
          return _c(
            "el-dropdown-item",
            {
              key: column.label,
              class: {
                "dropdown-item-active": _vm.isColumnVisible(column.key),
                hidden: column.isHiddenForCustomer && !_vm.isAdminOrSuperAdmin
              },
              nativeOn: {
                click: function($event) {
                  return _vm.toggleColumn(column.key)
                }
              }
            },
            [
              _c("i", {
                staticClass: "dropdown-item-icon-checked far fa-check-square"
              }),
              _c("i", {
                staticClass: "dropdown-item-icon-unchecked far fa-square"
              }),
              _vm._v(" " + _vm._s(column.label) + " ")
            ]
          )
        }),
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }