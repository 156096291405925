var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "page-title-group" },
        [
          _c("h1", [_vm._v("Leads Table")]),
          _c(
            "el-button",
            {
              attrs: { loading: _vm.exportLoading, type: "accent" },
              on: { click: _vm.doExport }
            },
            [
              _c("i", { staticClass: "fas fa-cloud-download-alt" }),
              _vm._v(" Export ")
            ]
          )
        ],
        1
      ),
      _c(
        "FilterPanel",
        [_c("LeadsTableFilters", { on: { filter: _vm.doFilter } })],
        1
      ),
      _c("MainPanel", [
        _c("div", { staticClass: "action-bar" }, [
          _c("div"),
          _c("div", { staticClass: "table-total" }, [
            _vm._v(
              " Total Campaigns: " +
                _vm._s(_vm.leads && _vm.leads.campaigns.length) +
                " "
            )
          ])
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "table-loading"
          },
          [
            _c("LeadsTable", {
              on: { sort: _vm.doSort, "refetch-data": _vm.getLeads }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }