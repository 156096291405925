var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ]
    },
    [
      (_vm.list && _vm.list.length) || _vm.loading
        ? _c("div", { staticClass: "table-wrapper" }, [
            _c("table", { staticClass: "table" }, [
              _c("thead", [
                _c("tr", [
                  _c(
                    "th",
                    [
                      _c("SortLabel", {
                        attrs: {
                          label: "URL",
                          order: _vm.$_sortingMixin_checkOrder("url")
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.$_sortingMixin_doSort("url")
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "th",
                    [
                      _c("SortLabel", {
                        attrs: {
                          label: "Campaign",
                          order: _vm.$_sortingMixin_checkOrder("campaign_id")
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.$_sortingMixin_doSort("campaign_id")
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "th",
                    { staticClass: "text-center" },
                    [
                      _c("SortLabel", {
                        attrs: {
                          label: "Status",
                          order: _vm.$_sortingMixin_checkOrder("status")
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.$_sortingMixin_doSort("status")
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "th",
                    [
                      _c("SortLabel", {
                        attrs: {
                          label: "IP",
                          order: _vm.$_sortingMixin_checkOrder("ip")
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.$_sortingMixin_doSort("ip")
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "th",
                    { staticClass: "text-center" },
                    [
                      _c("SortLabel", {
                        attrs: {
                          label: "Domains",
                          order: _vm.$_sortingMixin_checkOrder(
                            "incoming_domains_from_links_count"
                          )
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.$_sortingMixin_doSort(
                              "incoming_domains_from_links_count"
                            )
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "th",
                    { staticClass: "text-center" },
                    [
                      _c("SortLabel", {
                        attrs: {
                          label: "Links",
                          order: _vm.$_sortingMixin_checkOrder(
                            "incoming_links_count"
                          )
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.$_sortingMixin_doSort(
                              "incoming_links_count"
                            )
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "th",
                    { staticClass: "text-center" },
                    [
                      _c("SortLabel", {
                        attrs: {
                          label: "301's",
                          order: _vm.$_sortingMixin_checkOrder(
                            "incoming_redirects_count"
                          )
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.$_sortingMixin_doSort(
                              "incoming_redirects_count"
                            )
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c("th", { staticClass: "fixed-right-column text-center" }, [
                    _vm._v(" Actions ")
                  ])
                ])
              ]),
              _vm.list
                ? _c(
                    "tbody",
                    _vm._l(_vm.list, function(site) {
                      return _c("tr", { key: site.id }, [
                        _c("td", [
                          _c(
                            "a",
                            {
                              staticClass: "external-link",
                              attrs: { href: "//" + site.url, target: "_blank" }
                            },
                            [
                              _vm._v(" " + _vm._s(site.url) + " "),
                              _c("i", {
                                staticClass: "fas fa-external-link-alt"
                              })
                            ]
                          )
                        ]),
                        _c("td", [
                          _vm._v(
                            " " +
                              _vm._s(site.campaign && site.campaign.name) +
                              " "
                          )
                        ]),
                        _c("td", { staticClass: "text-center" }, [
                          _c(
                            "div",
                            {
                              class: +site.status
                                ? "text-success"
                                : "text-danger"
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.getSiteStatusById(site.status)) +
                                  " "
                              )
                            ]
                          )
                        ]),
                        _c("td", [
                          _vm._v(" " + _vm._s(site.ip && site.ip.ip) + " ")
                        ]),
                        _c(
                          "td",
                          { staticClass: "text-center" },
                          [
                            site.incoming_domains_from_links_count
                              ? _c(
                                  "router-link",
                                  {
                                    staticClass: "external-link",
                                    attrs: {
                                      to: {
                                        name: "NetworkSites",
                                        params: { host: site.url }
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          site.incoming_domains_from_links_count
                                        ) +
                                        " "
                                    ),
                                    _c("i", {
                                      staticClass: "fas fa-external-link-alt"
                                    })
                                  ]
                                )
                              : _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        site.incoming_domains_from_links_count
                                      ) +
                                      " "
                                  )
                                ])
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "text-center" },
                          [
                            site.incoming_links_count
                              ? _c(
                                  "router-link",
                                  {
                                    staticClass: "external-link",
                                    attrs: {
                                      to: {
                                        name: "OutgoingLinks",
                                        params: { url: site.url }
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(site.incoming_links_count) +
                                        " "
                                    ),
                                    _c("i", {
                                      staticClass: "fas fa-external-link-alt"
                                    })
                                  ]
                                )
                              : _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(site.incoming_links_count) +
                                      " "
                                  )
                                ])
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "text-center" },
                          [
                            site.incoming_redirects_count
                              ? _c(
                                  "router-link",
                                  {
                                    staticClass: "external-link",
                                    attrs: {
                                      to: {
                                        name: "RedirectDomains",
                                        params: { url: site.url }
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(site.incoming_redirects_count) +
                                        " "
                                    ),
                                    _c("i", {
                                      staticClass: "fas fa-external-link-alt"
                                    })
                                  ]
                                )
                              : _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(site.incoming_redirects_count) +
                                      " "
                                  )
                                ])
                          ],
                          1
                        ),
                        _c(
                          "td",
                          {
                            staticClass:
                              "fixed-right-column table__action-column"
                          },
                          [
                            _c(
                              "el-button-group",
                              [
                                _c("el-button", {
                                  attrs: {
                                    type: "primary",
                                    size: "mini",
                                    icon: "el-icon-edit",
                                    title: "Edit"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.handleEdit(site)
                                    }
                                  }
                                }),
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "gray",
                                      size: "mini",
                                      title: "Logs",
                                      icon: "el-icon-tickets"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.handleLogs(site)
                                      }
                                    }
                                  },
                                  [_vm._v(" Logs ")]
                                ),
                                _c("el-button", {
                                  attrs: {
                                    type: "danger",
                                    size: "mini",
                                    title: "Delete",
                                    icon: "el-icon-delete"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.handleDelete(site)
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ])
                    }),
                    0
                  )
                : _vm._e()
            ])
          ])
        : _c("EmptyList")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }