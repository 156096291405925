var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    { staticClass: "filter-form", attrs: { "label-position": "top" } },
    [
      _c(
        "el-form-item",
        {
          staticClass: "multiselect-campaign-wrapper",
          attrs: { label: "Campaigns" }
        },
        [
          _c(
            "el-select",
            {
              attrs: { filterable: "", multiple: "" },
              on: { change: _vm.doFilter },
              model: {
                value: _vm.filters.campaigns_ids,
                callback: function($$v) {
                  _vm.$set(_vm.filters, "campaigns_ids", $$v)
                },
                expression: "filters.campaigns_ids"
              }
            },
            _vm._l(_vm.allCampaigns, function(campaign) {
              return _c("el-option", {
                key: campaign.id,
                attrs: { label: campaign.name, value: campaign.id }
              })
            }),
            1
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Category" } },
        [
          _c(
            "el-select",
            {
              attrs: { filterable: "" },
              on: { change: _vm.doFilter },
              model: {
                value: _vm.filters.category_id,
                callback: function($$v) {
                  _vm.$set(_vm.filters, "category_id", $$v)
                },
                expression: "filters.category_id"
              }
            },
            _vm._l(_vm.campaignCategories, function(category) {
              return _c("el-option", {
                key: category.id,
                attrs: { label: category.name, value: category.id }
              })
            }),
            1
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Clients only" } },
        [
          _c("el-switch", {
            attrs: { "inactive-value": 0, "active-value": 1 },
            on: { change: _vm.doFilter },
            model: {
              value: _vm.filters.client_only,
              callback: function($$v) {
                _vm.$set(_vm.filters, "client_only", $$v)
              },
              expression: "filters.client_only"
            }
          })
        ],
        1
      ),
      _vm.isFiltersChanged
        ? _c(
            "el-button",
            { attrs: { type: "gray" }, on: { click: _vm.clearFilters } },
            [_vm._v(" Reset filters ")]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }