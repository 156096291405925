var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-limit-switcher" },
    [
      _c("div", { staticClass: "page-limit-switcher__label" }, [
        _vm._v(" Per page: ")
      ]),
      _vm._l(_vm.variants, function(variant) {
        return _c(
          "div",
          {
            key: variant,
            staticClass: "page-limit-switcher__item",
            class: {
              "page-limit-switcher__item--active": variant === _vm.active
            },
            on: {
              click: function($event) {
                return _vm.$emit("click", variant)
              }
            }
          },
          [_vm._v(" " + _vm._s(variant) + " ")]
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }