var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "geo-object-list" }, [
    _vm.geoObjects && _vm.geoObjects.zipCodes.length
      ? _c("div", [
          _c(
            "div",
            { staticClass: "col-md-6" },
            [
              _c("div", { staticClass: "geo-objects-label" }, [
                _vm._v("Zip codes")
              ]),
              _vm._l(_vm.geoObjects.zipCodes, function(zip) {
                return _c("div", { key: zip }, [
                  _vm._v(" " + _vm._s(zip) + " ")
                ])
              })
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "col-md-6" },
            [
              _c("div", { staticClass: "geo-objects-label" }, [
                _vm._v("Cities")
              ]),
              _vm._l(_vm.geoObjects.cities, function(city) {
                return _c("div", { key: city }, [
                  _vm._v(" " + _vm._s(city) + " ")
                ])
              })
            ],
            2
          )
        ])
      : _c("div", [
          _c(
            "div",
            { staticClass: "geo-object-list__notification" },
            [
              _c("i", { staticClass: "el-icon-caret-left" }),
              _c("el-alert", {
                attrs: {
                  title: "There is no area yet.",
                  description:
                    "You can draw an area using polygon drawing tool.",
                  type: "info",
                  "show-icon": "",
                  closable: false
                }
              })
            ],
            1
          )
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }