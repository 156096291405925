var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login-page" }, [
    _vm._m(0),
    _c("div", { staticClass: "panel panel-default" }, [
      _c("div", { staticClass: "panel-heading" }, [
        _vm._v(" Administrator Login ")
      ]),
      _c(
        "div",
        { staticClass: "panel-body" },
        [
          _c("el-alert", {
            staticClass: "alert",
            attrs: { type: "info", closable: false, "show-icon": "" },
            scopedSlots: _vm._u([
              {
                key: "title",
                fn: function() {
                  return [
                    _vm._v(" If you are a client please click "),
                    _c(
                      "a",
                      {
                        attrs: { href: "https://clients.markmywordsmedia.com" }
                      },
                      [_c("strong", [_vm._v("here")])]
                    ),
                    _vm._v(" to log in. ")
                  ]
                },
                proxy: true
              }
            ])
          }),
          _c("div"),
          _c("LoginForm")
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "logo-wrapper" }, [
      _c("img", {
        staticClass: "logo",
        attrs: { src: require("@/assets/img/logo.png"), alt: "Logo" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }