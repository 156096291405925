var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._m(0),
      _c(
        "FilterPanel",
        [
          _c("ChartFilters", {
            attrs: {
              defaultDataValues: _vm.defaultDataValues,
              "chart-type": _vm.chartType
            },
            on: {
              filter: _vm.doFilter,
              "change-chart-type": _vm.changeChartType
            }
          })
        ],
        1
      ),
      _c(
        "MainPanel",
        [
          _c(
            "div",
            { staticClass: "chart-top-bar" },
            [
              _c(
                "el-select",
                {
                  staticClass: "multiselect campaign-select",
                  attrs: { filterable: "" },
                  on: { change: _vm.getLeadsChart },
                  model: {
                    value: _vm.filterObject.campaign_id,
                    callback: function($$v) {
                      _vm.$set(_vm.filterObject, "campaign_id", $$v)
                    },
                    expression: "filterObject.campaign_id"
                  }
                },
                _vm._l(
                  [{ id: null, name: "All campaigns" }].concat(
                    _vm.allCampaigns
                  ),
                  function(campaign) {
                    return _c("el-option", {
                      key: campaign.id,
                      attrs: { label: campaign.name, value: campaign.id }
                    })
                  }
                ),
                1
              ),
              _vm.filterObject.campaign_id
                ? _c("ChartSummary", { attrs: { loading: _vm.loading } })
                : _vm._e()
            ],
            1
          ),
          _c("Chart", {
            attrs: { loading: _vm.loading, "chart-type": _vm.chartType }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-title-group" }, [
      _c("h1", [_vm._v("Leads Chart")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }