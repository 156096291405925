var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "record" },
    [
      _vm.url
        ? _c("div", { staticClass: "record__audio-wrapper" }, [
            _c("audio", {
              ref: "audio",
              attrs: {
                controls: "controls",
                src: _vm.url,
                autoplay: "autoplay"
              }
            }),
            _c("div", { staticClass: "record__speed-list" }, [
              _c(
                "div",
                {
                  staticClass: "record__speed-item",
                  class: {
                    "record__speed-item--active": _vm.currentSpeed === 1
                  },
                  on: {
                    click: function($event) {
                      return _vm.changeSpeed(1)
                    }
                  }
                },
                [_vm._v(" 1x ")]
              ),
              _c("div", { staticClass: "record__speed-divider" }),
              _c(
                "div",
                {
                  staticClass: "record__speed-item",
                  class: {
                    "record__speed-item--active": _vm.currentSpeed === 1.5
                  },
                  on: {
                    click: function($event) {
                      return _vm.changeSpeed(1.5)
                    }
                  }
                },
                [_vm._v(" 1.5x ")]
              ),
              _c("div", { staticClass: "record__speed-divider" }),
              _c(
                "div",
                {
                  staticClass: "record__speed-item",
                  class: {
                    "record__speed-item--active": _vm.currentSpeed === 2
                  },
                  on: {
                    click: function($event) {
                      return _vm.changeSpeed(2)
                    }
                  }
                },
                [_vm._v(" 2x ")]
              )
            ])
          ])
        : _c(
            "el-tooltip",
            { attrs: { effect: "dark", content: "Play", placement: "left" } },
            [
              _c(
                "div",
                {
                  staticClass: "record__icon-wrapper",
                  on: { click: _vm.play }
                },
                [
                  _c("i", {
                    staticClass: "fa fa-play-circle record__play-button"
                  }),
                  _c("div", { staticClass: "record__duration" }, [
                    _vm._v(" " + _vm._s(_vm.formattedDuration) + " ")
                  ])
                ]
              )
            ]
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }