var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.lead
    ? _c("div", [
        _vm.lead.message && _vm.lead.message.length > _vm.messageMaxLength
          ? _c("div", [
              _vm.messageCollapsed
                ? _c(
                    "div",
                    [
                      _c("nl2br", {
                        attrs: { tag: "p", text: _vm.croppedMessage }
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "read-more-link",
                          on: {
                            click: function($event) {
                              _vm.messageCollapsed = !_vm.messageCollapsed
                            }
                          }
                        },
                        [_vm._v(" Read More ")]
                      )
                    ],
                    1
                  )
                : _c(
                    "div",
                    [
                      _c("nl2br", {
                        attrs: { tag: "p", text: _vm.lead.message }
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "read-more-link",
                          on: {
                            click: function($event) {
                              _vm.messageCollapsed = !_vm.messageCollapsed
                            }
                          }
                        },
                        [_vm._v(" Read Less ")]
                      )
                    ],
                    1
                  )
            ])
          : _c("div", [_vm._v(" " + _vm._s(_vm.lead.message) + " ")])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }