var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "mb-2" },
      [
        _c(
          "el-checkbox",
          {
            attrs: { indeterminate: _vm.isIndeterminate },
            on: { change: _vm.handleCheckAll },
            model: {
              value: _vm.allChecked,
              callback: function($$v) {
                _vm.allChecked = $$v
              },
              expression: "allChecked"
            }
          },
          [_vm._v(" Check all ")]
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "checkbox-list mb-1" },
      [
        _c(
          "el-checkbox-group",
          {
            on: { change: _vm.handleCheck },
            model: {
              value: _vm.checkedPages,
              callback: function($$v) {
                _vm.checkedPages = $$v
              },
              expression: "checkedPages"
            }
          },
          _vm._l(_vm.pagesList, function(page) {
            return _c(
              "el-checkbox",
              { key: page.id, attrs: { label: page.id } },
              [_vm._v(" " + _vm._s(page.title) + " ")]
            )
          }),
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }