var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.user
    ? _c(
        "ul",
        {
          staticClass: "sidebar-panel nav",
          class: { closed: !_vm.opened && !_vm.hovered },
          on: {
            mouseenter: _vm.mouseEnterCollapse,
            mouseleave: _vm.mouseLeaveCollapse
          }
        },
        [
          _c("li", { staticClass: "hamburger" }, [
            _c(
              "div",
              {
                attrs: { title: "Collapse Menu" },
                on: { click: _vm.toggleSidebar }
              },
              [_c("i", { staticClass: "fas fa-bars" })]
            )
          ]),
          _vm.user.permissions.dashboard
            ? _c(
                "li",
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: { name: "Home" },
                        exact: "",
                        "active-class": "active"
                      }
                    },
                    [
                      _c("i", { staticClass: "fas fa-home" }),
                      _c("span", { staticClass: "nav-label" }, [
                        _vm._v("Dashboard")
                      ])
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.user.permissions.admin_pages
            ? _c(
                "li",
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: { name: "AdminAccess" },
                        "active-class": "active"
                      }
                    },
                    [
                      _c("i", { staticClass: "fa fa-user-circle" }),
                      _c("span", { staticClass: "nav-label" }, [
                        _vm._v("Admin Access")
                      ])
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.user.permissions.leads
            ? _c(
                "li",
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: { name: "Leads" },
                        exact: "",
                        "active-class": "active"
                      }
                    },
                    [
                      _c("i", { staticClass: "fa fa-tag" }),
                      _c("span", { staticClass: "nav-label" }, [
                        _vm._v("Leads")
                      ])
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.user.permissions.leadsRelated
            ? _c(
                "li",
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: { name: "LeadsRelated" },
                        "active-class": "active"
                      }
                    },
                    [
                      _c("i", { staticClass: "fa fa-tag" }),
                      _c("span", { staticClass: "nav-label" }, [
                        _vm._v("Related Leads")
                      ])
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.user.permissions.archivedleads
            ? _c(
                "li",
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: { name: "LeadsArchive" },
                        "active-class": "active"
                      }
                    },
                    [
                      _c("i", { staticClass: "fa fa-archive" }),
                      _c("span", { staticClass: "nav-label" }, [
                        _vm._v("Leads Archive")
                      ])
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.user.permissions.leadChart
            ? _c(
                "li",
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: { name: "LeadsTable" },
                        "active-class": "active"
                      }
                    },
                    [
                      _c("i", { staticClass: "fas fa-table" }),
                      _c("span", { staticClass: "nav-label" }, [
                        _vm._v("Leads Table")
                      ])
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.user.permissions.leadChart
            ? _c(
                "li",
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: { name: "LeadsChart" },
                        "active-class": "active"
                      }
                    },
                    [
                      _c("i", { staticClass: "fa fa-chart-area" }),
                      _c("span", { staticClass: "nav-label" }, [
                        _vm._v("Leads Chart")
                      ])
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.user.permissions.campaigns
            ? _c(
                "li",
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: { name: "Campaigns" },
                        exact: "",
                        "active-class": "active"
                      }
                    },
                    [
                      _c("i", { staticClass: "fa fa-flag" }),
                      _c("span", { staticClass: "nav-label" }, [
                        _vm._v("Campaigns")
                      ])
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.user.permissions.changelog
            ? _c(
                "li",
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: { name: "CampaignsChangelog" },
                        exact: "",
                        "active-class": "active"
                      }
                    },
                    [
                      _c("i", { staticClass: "fa fa-list-alt" }),
                      _c("span", { staticClass: "nav-label" }, [
                        _vm._v("Changelog")
                      ])
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.user.permissions.activityLog
            ? _c(
                "li",
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: { name: "ActivityLog" },
                        "active-class": "active"
                      }
                    },
                    [
                      _c("i", { staticClass: "fa fa-list-alt" }),
                      _c("span", { staticClass: "nav-label" }, [
                        _vm._v("Activity Log")
                      ])
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.user.permissions.campaignCategories
            ? _c(
                "li",
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: { name: "CampaignCategories" },
                        "active-class": "active"
                      }
                    },
                    [
                      _c("i", { staticClass: "fa fa-folder" }),
                      _c("span", { staticClass: "nav-label" }, [
                        _vm._v("Campaign Categories")
                      ])
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.user.permissions.campaignMap
            ? _c(
                "li",
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: { name: "CampaignGmbsMap" },
                        "active-class": "active"
                      }
                    },
                    [
                      _c("i", { staticClass: "fa fa-map-marker" }),
                      _c("span", { staticClass: "nav-label" }, [
                        _vm._v("Campaign Map")
                      ])
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.user.permissions.campaignGmbs
            ? _c(
                "li",
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: { name: "CampaignGmbs" },
                        exact: "",
                        "active-class": "active"
                      }
                    },
                    [
                      _c("i", { staticClass: "fa fa-folder" }),
                      _c("span", { staticClass: "nav-label" }, [_vm._v("GMBs")])
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.user.permissions.googleLocations
            ? _c(
                "li",
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: { name: "GMBManager" },
                        exact: "",
                        "active-class": "active"
                      }
                    },
                    [
                      _c("i", { staticClass: "fa fa-map-marker" }),
                      _c("span", { staticClass: "nav-label" }, [
                        _vm._v("GMB Manager")
                      ])
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.user.permissions.clients
            ? _c(
                "li",
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: { name: "Clients" },
                        exact: "",
                        "active-class": "active"
                      }
                    },
                    [
                      _c("i", { staticClass: "fa fa-user" }),
                      _c("span", { staticClass: "nav-label" }, [
                        _vm._v("Clients")
                      ])
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.user.permissions.clientSites
            ? _c(
                "li",
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: { name: "ClientSites" },
                        "active-class": "active"
                      }
                    },
                    [
                      _c("i", { staticClass: "fa fa-file-alt" }),
                      _c("span", { staticClass: "nav-label" }, [
                        _vm._v("Client Sites")
                      ])
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.user.permissions.networkSites
            ? _c(
                "li",
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: { name: "NetworkSites" },
                        exact: "",
                        "active-class": "active"
                      }
                    },
                    [
                      _c("i", { staticClass: "fa fa-sitemap" }),
                      _c("span", { staticClass: "nav-label" }, [
                        _vm._v("Network Sites")
                      ])
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.user.permissions.outgoingLinks
            ? _c(
                "li",
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: { name: "OutgoingLinks" },
                        "active-class": "active"
                      }
                    },
                    [
                      _c("i", { staticClass: "fa fa-sitemap" }),
                      _c("span", { staticClass: "nav-label" }, [
                        _vm._v("Links")
                      ])
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.user.permissions.networkCategories
            ? _c(
                "li",
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: { name: "Categories" },
                        "active-class": "active"
                      }
                    },
                    [
                      _c("i", { staticClass: "fa fa-folder" }),
                      _c("span", { staticClass: "nav-label" }, [
                        _vm._v("Network Categories")
                      ])
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.user.permissions.redirectDomains
            ? _c(
                "li",
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: { name: "RedirectDomains" },
                        "active-class": "active"
                      }
                    },
                    [
                      _c("i", { staticClass: "fa fa-redo" }),
                      _c("span", { staticClass: "nav-label" }, [
                        _vm._v("301 Domains List")
                      ])
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.user.permissions.ipAddresses
            ? _c(
                "li",
                [
                  _c(
                    "router-link",
                    {
                      attrs: { to: { name: "Ips" }, "active-class": "active" }
                    },
                    [
                      _c("i", { staticClass: "fa fa-hashtag" }),
                      _c("span", { staticClass: "nav-label" }, [
                        _vm._v("IP Addresses")
                      ])
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._m(0),
          _vm._m(1)
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c(
        "a",
        {
          attrs: {
            href: "https://www.markmywordsmedia.com/lead-manager/",
            target: "_blank"
          }
        },
        [
          _c("i", { staticClass: "fa fa-question" }),
          _c("span", { staticClass: "nav-label" }, [_vm._v("User Guide")])
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c(
        "a",
        {
          attrs: {
            href: "https://www.markmywordsmedia.com/resources/",
            target: "_blank"
          }
        },
        [
          _c("i", { staticClass: "fa fa-file" }),
          _c("span", { staticClass: "nav-label" }, [_vm._v("Resources")])
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }