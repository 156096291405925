var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      attrs: { model: _vm.form, rules: _vm.rules, "label-position": "top" }
    },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.visible,
            "before-close": _vm.handleClose,
            title: _vm.isEdit ? "Edit Client" : "Create Client",
            width: "94%"
          }
        },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-6" },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "Name", prop: "name" } },
                  [
                    _c("el-input", {
                      model: {
                        value: _vm.form.name,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "name", $$v)
                        },
                        expression: "form.name"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-6" },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "Nickname", prop: "username" } },
                  [
                    _c("el-input", {
                      model: {
                        value: _vm.form.username,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "username", $$v)
                        },
                        expression: "form.username"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-6" },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "Email", prop: "email" } },
                  [
                    _c("el-input", {
                      model: {
                        value: _vm.form.email,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "email", $$v)
                        },
                        expression: "form.email"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-6" },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.isEdit ? "Change Password" : "Password",
                      prop: "password",
                      rules: _vm.isEdit
                        ? _vm.rules.passwordForEdit
                        : _vm.rules.passwordForCreate
                    }
                  },
                  [
                    _c("el-input", {
                      attrs: { placeholder: _vm.isEdit ? "*********" : "" },
                      model: {
                        value: _vm.form.password,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "password", $$v)
                        },
                        expression: "form.password"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-6" },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "Additional Emails", prop: "emails" } },
                  [
                    _c("div", { staticClass: "label-description" }, [
                      _vm._v(" Separate with line break ")
                    ]),
                    _c("el-input", {
                      attrs: { type: "textarea", rows: 5 },
                      model: {
                        value: _vm.form.emails,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "emails", $$v)
                        },
                        expression: "form.emails"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-6" },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "Timezone", prop: "timezone" } },
                  [
                    _c(
                      "el-select",
                      {
                        staticClass: "multiselect",
                        attrs: { filterable: "" },
                        model: {
                          value: _vm.form.timezone,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "timezone", $$v)
                          },
                          expression: "form.timezone"
                        }
                      },
                      _vm._l(_vm.timezones, function(key, value) {
                        return _c("el-option", {
                          key: key,
                          attrs: { label: key, value: value }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _vm.isEdit
                  ? _c("el-form-item", [
                      _c(
                        "div",
                        { staticClass: "switch-wrapper" },
                        [
                          _c("el-switch", {
                            attrs: { "inactive-value": 0, "active-value": 1 },
                            model: {
                              value: _vm.form.disable_notifications,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "disable_notifications", $$v)
                              },
                              expression: "form.disable_notifications"
                            }
                          }),
                          _c("div", { staticClass: "switch-label" }, [
                            _vm._v(
                              " Disable missed call notifications and warnings "
                            )
                          ])
                        ],
                        1
                      )
                    ])
                  : _vm._e()
              ],
              1
            )
          ]),
          _vm.isEdit && _vm.remoteSSOToken
            ? _c("div", [
                _c("label", [_vm._v("Remote SSO Token")]),
                _c(
                  "div",
                  { staticClass: "token" },
                  [
                    _c(
                      "CopyToClipboard",
                      {
                        staticClass: "token__overlay",
                        attrs: { copy: _vm.remoteSSOToken }
                      },
                      [_c("i", { staticClass: "fas fa-copy" })]
                    ),
                    _vm._v(" " + _vm._s(_vm.remoteSSOToken) + " ")
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm.isEdit
            ? _c(
                "el-button",
                {
                  staticClass: "token__button",
                  attrs: {
                    type: "gray",
                    size: "mini",
                    icon: "el-icon-refresh-right"
                  },
                  on: { click: _vm.generateToken }
                },
                [_vm._v(" Generate Remote Token ")]
              )
            : _vm._e(),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("Cancel")
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                [_vm._v("Save")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }