var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      attrs: { model: _vm.form, rules: _vm.rules, "label-position": "top" }
    },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "before-close": _vm.handleClose,
            title: _vm.isEdit ? "Edit Campaign" : "Create New Campaign",
            visible: _vm.visible,
            width: "94%"
          }
        },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-6" },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "Campaign Name", prop: "name" } },
                  [
                    _c("el-input", {
                      model: {
                        value: _vm.form.name,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "name", $$v)
                        },
                        expression: "form.name"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "Categories", prop: "categories" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { multiple: "" },
                        model: {
                          value: _vm.form.categories,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "categories", $$v)
                          },
                          expression: "form.categories"
                        }
                      },
                      _vm._l(_vm.campaignCategories, function(category) {
                        return _c("el-option", {
                          key: category.id,
                          attrs: { label: category.name, value: category.id }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "Client", prop: "client_id" } },
                  [
                    _c(
                      "el-select",
                      {
                        model: {
                          value: _vm.form.client_id,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "client_id", $$v)
                          },
                          expression: "form.client_id"
                        }
                      },
                      _vm._l(_vm.clientsList && _vm.clientsList.data, function(
                        client
                      ) {
                        return _c("el-option", {
                          key: client.id,
                          attrs: { label: client.name, value: client.id }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "Client Sites" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { multiple: "" },
                        model: {
                          value: _vm.form.client_sites,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "client_sites", $$v)
                          },
                          expression: "form.client_sites"
                        }
                      },
                      _vm._l(_vm.clientSites, function(site) {
                        return _c("el-option", {
                          key: site.id,
                          attrs: { label: site.url, value: site.id }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "Manager", prop: "manager" } },
                  [
                    _c("el-input", {
                      model: {
                        value: _vm.form.manager,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "manager", $$v)
                        },
                        expression: "form.manager"
                      }
                    })
                  ],
                  1
                ),
                _c("el-link", { attrs: { type: "primary" } }, [
                  _vm._v("Edit working hours")
                ])
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-6" },
              [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-sm-6" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Start Date", prop: "start_date" } },
                        [
                          _c("el-date-picker", {
                            attrs: { format: "MM/dd/yyyy", type: "date" },
                            model: {
                              value: _vm.form.start_date,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "start_date", $$v)
                              },
                              expression: "form.start_date"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-sm-6" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Due Date", prop: "due_date" } },
                        [
                          _c(
                            "el-select",
                            {
                              model: {
                                value: _vm.form.due_date,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "due_date", _vm._n($$v))
                                },
                                expression: "form.due_date"
                              }
                            },
                            _vm._l(
                              Array.from({ length: 31 }, function(_, i) {
                                return i + 1
                              }),
                              function(day) {
                                return _c("el-option", {
                                  key: day,
                                  attrs: { label: day, value: day }
                                })
                              }
                            ),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-sm-4" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Initial Target",
                            prop: "initial_target"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { value: _vm.form.initial_target },
                            on: {
                              input: function($event) {
                                return _vm.toNumber($event, "initial_target")
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-sm-4" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "3 Month Target",
                            prop: "month_3_target"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { value: _vm.form.month_3_target },
                            on: {
                              input: function($event) {
                                return _vm.toNumber($event, "month_3_target")
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-sm-4" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "6 Month Target",
                            prop: "month_6_target"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { value: _vm.form.month_6_target },
                            on: {
                              input: function($event) {
                                return _vm.toNumber($event, "month_6_target")
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-sm-4" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "9 Month Target",
                            prop: "month_9_target"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { value: _vm.form.month_9_target },
                            on: {
                              input: function($event) {
                                return _vm.toNumber($event, "month_9_target")
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-sm-4" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "12 Month Target",
                            prop: "month_12_target"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { value: _vm.form.month_12_target },
                            on: {
                              input: function($event) {
                                return _vm.toNumber($event, "month_12_target")
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-sm-6" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Service Fee" } },
                        [
                          _c("currency-input", {
                            staticClass: "el-input__inner",
                            model: {
                              value: _vm.form.service_fee,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "service_fee", $$v)
                              },
                              expression: "form.service_fee"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-sm-6" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Approved PPC" } },
                        [
                          _c("currency-input", {
                            staticClass: "el-input__inner",
                            model: {
                              value: _vm.form.approved_ppc,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "approved_ppc", $$v)
                              },
                              expression: "form.approved_ppc"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]),
                _c(
                  "el-form-item",
                  [
                    _c("el-switch", {
                      attrs: {
                        "active-value": 1,
                        "inactive-value": 0,
                        "active-text": "Sticky in changelog"
                      },
                      model: {
                        value: _vm.form.fixed_to_top,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "fixed_to_top", $$v)
                        },
                        expression: "form.fixed_to_top"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("Cancel")
              ]),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    disabled: _vm.inProgress,
                    loading: _vm.inProgress
                  },
                  on: { click: _vm.onSubmit }
                },
                [_vm._v("Confirm")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }