var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      staticClass: "filter-form",
      attrs: { model: _vm.filters, "label-position": "top" }
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "Campaigns and Teams" } },
        [
          _c(
            "el-select",
            {
              staticClass: "multiselect",
              attrs: { multiple: "", filterable: "" },
              on: { change: _vm.doFilter },
              model: {
                value: _vm.filters.campaign_ids,
                callback: function($$v) {
                  _vm.$set(_vm.filters, "campaign_ids", $$v)
                },
                expression: "filters.campaign_ids"
              }
            },
            _vm._l(_vm.campaignsWithTeams, function(campaign) {
              return _c("el-option", {
                key: campaign.id,
                attrs: { label: campaign.name, value: campaign.id }
              })
            }),
            1
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Admin users" } },
        [
          _c(
            "el-select",
            {
              staticClass: "multiselect",
              attrs: { multiple: "", filterable: "" },
              on: { change: _vm.doFilter },
              model: {
                value: _vm.filters.user_ids,
                callback: function($$v) {
                  _vm.$set(_vm.filters, "user_ids", $$v)
                },
                expression: "filters.user_ids"
              }
            },
            _vm._l(_vm.adminsWithSuperAdmin, function(admin) {
              return _c("el-option", {
                key: admin.id,
                attrs: { label: admin.username, value: admin.id }
              })
            }),
            1
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Date Range" } },
        [
          _c("el-date-picker", {
            staticClass: "date-range-picker",
            attrs: {
              clearable: false,
              "end-placeholder": "End date",
              format: "MM/dd/yyyy",
              "range-separator": "To",
              "start-placeholder": "Start date",
              type: "daterange",
              "value-format": "yyyy-MM-dd"
            },
            on: { change: _vm.doFilter },
            model: {
              value: _vm.filters.dateRange,
              callback: function($$v) {
                _vm.$set(_vm.filters, "dateRange", $$v)
              },
              expression: "filters.dateRange"
            }
          })
        ],
        1
      ),
      _vm.isFiltersChanged
        ? _c(
            "el-button",
            { attrs: { type: "gray" }, on: { click: _vm.clearFilters } },
            [_vm._v(" Reset filters ")]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }