var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isGuest
    ? _c(
        "div",
        [
          _c("TopNav", { on: { "edit-profile": _vm.openEditProfileDialog } }),
          _c("div", [
            _c(
              "div",
              {
                staticClass: "sidebar",
                style: "background: " + _vm.sidebarColor
              },
              [_c("SideNav")],
              1
            ),
            _c(
              "div",
              { staticClass: "site-content authenticated" },
              [
                _c(
                  "router-view",
                  { key: _vm.$route.path },
                  [_vm._t("default")],
                  2
                )
              ],
              1
            )
          ]),
          _c("ProfileEditDialog", {
            attrs: { visible: _vm.addEditDialogVisible },
            on: {
              "update:visible": function($event) {
                _vm.addEditDialogVisible = $event
              },
              close: _vm.closeEditProfileDialog
            }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }