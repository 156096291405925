var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.visible,
        "before-close": _vm.handleClose,
        title: "Status logs for: " + (_vm.activeGmb && _vm.activeGmb.title),
        width: "94%",
        top: "70px"
      }
    },
    [_c("GmbLog")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }