var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ]
    },
    [
      (_vm.list && _vm.list.length) || _vm.loading
        ? _c("div", { staticClass: "table-wrapper" }, [
            _c("table", { staticClass: "table" }, [
              _c("thead", [
                _c("tr", [
                  _c(
                    "th",
                    [
                      _c("SortLabel", {
                        attrs: {
                          label: "Title",
                          order: _vm.$_sortingMixin_checkOrder("title")
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.$_sortingMixin_doSort("title")
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c("th", { staticClass: "text-center" }, [
                    _vm._v(" Map Link ")
                  ]),
                  _c(
                    "th",
                    [
                      _c("SortLabel", {
                        attrs: {
                          label: "Address",
                          order: _vm.$_sortingMixin_checkOrder("address")
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.$_sortingMixin_doSort("address")
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "th",
                    [
                      _c("SortLabel", {
                        attrs: {
                          label: "Phone",
                          order: _vm.$_sortingMixin_checkOrder("phone")
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.$_sortingMixin_doSort("phone")
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "th",
                    [
                      _c("SortLabel", {
                        attrs: {
                          label: "Website",
                          order: _vm.$_sortingMixin_checkOrder("website")
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.$_sortingMixin_doSort("website")
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c("th", [_vm._v(" Service Area ")]),
                  _c("th", [_vm._v(" Primary Category ")]),
                  _c("th", [_vm._v(" Categories ")]),
                  _c(
                    "th",
                    [
                      _c("SortLabel", {
                        attrs: {
                          label: "Description",
                          order: _vm.$_sortingMixin_checkOrder("description")
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.$_sortingMixin_doSort("description")
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "th",
                    [
                      _c("SortLabel", {
                        attrs: {
                          label: "Email",
                          order: _vm.$_sortingMixin_checkOrder("email")
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.$_sortingMixin_doSort("email")
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c("th", { staticClass: "fixed-right-column text-center" }, [
                    _vm._v(" Actions ")
                  ])
                ])
              ]),
              _vm.list
                ? _c(
                    "tbody",
                    _vm._l(_vm.list, function(item) {
                      return _c("tr", { key: item.id }, [
                        _c("td", [_vm._v(" " + _vm._s(item.title) + " ")]),
                        _c("td", { staticClass: "text-center" }, [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: item.google_maps_url,
                                target: "_blank"
                              }
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "link-button",
                                  attrs: {
                                    type: "info",
                                    size: "mini",
                                    title: "Google maps link"
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "fas fa-external-link-alt"
                                  })
                                ]
                              )
                            ],
                            1
                          )
                        ]),
                        _c("td", [_vm._v(" " + _vm._s(item.address) + " ")]),
                        _c("td", { staticClass: "no-wrap" }, [
                          _vm._v(" " + _vm._s(item.phone) + " ")
                        ]),
                        _c("td", [_vm._v(" " + _vm._s(item.website) + " ")]),
                        _c("td", [
                          _vm._v(" " + _vm._s(item.service_area) + " ")
                        ]),
                        _c("td", { staticClass: "no-wrap" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                item.primary_category &&
                                  item.primary_category.name
                              ) +
                              " "
                          )
                        ]),
                        _c(
                          "td",
                          _vm._l(item.categories, function(category) {
                            return _c(
                              "div",
                              { key: category.id, staticClass: "no-wrap" },
                              [_vm._v(" " + _vm._s(category.name) + " ")]
                            )
                          }),
                          0
                        ),
                        _c("td", [
                          _vm._v(" " + _vm._s(item.description) + " ")
                        ]),
                        _c("td", [_vm._v(" " + _vm._s(item.email) + " ")]),
                        _c(
                          "td",
                          {
                            staticClass:
                              "fixed-right-column table__action-column"
                          },
                          [
                            _c(
                              "el-button-group",
                              [
                                _c("el-button", {
                                  attrs: {
                                    type: "primary",
                                    size: "mini",
                                    icon: "el-icon-edit",
                                    title: "Edit"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.handleEdit(item)
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ])
                    }),
                    0
                  )
                : _vm._e()
            ])
          ])
        : _c("EmptyList")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }