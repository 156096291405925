var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "page-title-group" },
        [
          _c("h1", [_vm._v("Campaign Categories")]),
          _c(
            "el-button",
            {
              attrs: { type: "accent", icon: "el-icon-plus" },
              on: { click: _vm.openAddEditDialog }
            },
            [_vm._v(" Create New Category ")]
          )
        ],
        1
      ),
      _c(
        "MainPanel",
        [
          _c("div", { staticClass: "action-bar" }, [
            _c("div"),
            _c("div", { staticClass: "table-total" }, [
              _vm._v(
                " Total Items: " +
                  _vm._s(
                    (_vm.campaignCategories && _vm.campaignCategories.length) ||
                      "-"
                  ) +
                  " "
              )
            ])
          ]),
          _c("CampaignCategoriesList", {
            attrs: { loading: _vm.loading },
            on: {
              edit: _vm.openAddEditDialog,
              "refetch-data": function($event) {
                return _vm.getCategoriesList(false)
              }
            }
          })
        ],
        1
      ),
      _c("AddEditCategoryDialog", {
        attrs: { visible: _vm.addEditDialogVisible },
        on: {
          "update:visible": function($event) {
            _vm.addEditDialogVisible = $event
          },
          close: _vm.closeAddEditDialog,
          "refetch-data": function($event) {
            return _vm.getCategoriesList(false)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }