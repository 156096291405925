var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "page-title-group" }, [
        _vm.category
          ? _c("h1", [
              _c(
                "a",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.removeParams.apply(null, arguments)
                    }
                  }
                },
                [_vm._v(" Campaigns ")]
              ),
              _c("span", [
                _vm._v("/ " + _vm._s(_vm.category.name) + " Category")
              ])
            ])
          : _c("h1", [_vm._v("Campaigns")]),
        _c(
          "div",
          { staticClass: "top-action-bar" },
          [
            _c("SearchFilterAlt", {
              attrs: { placeholder: "Search by Campaign or Client Name" },
              on: { filter: _vm.doFilter }
            }),
            _c(
              "el-button",
              {
                attrs: { icon: "el-icon-plus", type: "accent" },
                on: { click: _vm.openAddEditDialog }
              },
              [_vm._v(" Create New Campaign ")]
            )
          ],
          1
        )
      ]),
      _c(
        "el-tabs",
        {
          attrs: { type: "border-card" },
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "Active Campaigns", name: "active-campaigns" } },
            [
              _c("CampaignsList", {
                ref: "activeList",
                on: {
                  "edit-campaign": _vm.triggerCampaignEdit,
                  "edit-area": _vm.triggerEditArea,
                  "edit-working-hours": _vm.triggerWorkingHoursEdit
                }
              }),
              _c("CampaignsSummaryTable")
            ],
            1
          ),
          _c(
            "el-tab-pane",
            {
              attrs: { label: "Archived Campaigns", name: "archived-campaigns" }
            },
            [
              _c("CampaignsList", {
                ref: "archivedList",
                attrs: { isArchived: "" },
                on: {
                  "edit-campaign": _vm.triggerCampaignEdit,
                  "edit-area": _vm.triggerEditArea,
                  "edit-working-hours": _vm.triggerWorkingHoursEdit
                }
              }),
              _c("CampaignsSummaryTable", { attrs: { isArchived: "" } })
            ],
            1
          )
        ],
        1
      ),
      _c("AddEditCampaignDialog", {
        attrs: {
          activeCampaign: _vm.activeCampaign,
          visible: _vm.addEditDialogVisible
        },
        on: {
          "update:visible": function($event) {
            _vm.addEditDialogVisible = $event
          },
          close: _vm.closeAddEditDialog
        }
      }),
      _vm.editAreaDialogVisible
        ? _c("EditAreaDialog", {
            attrs: {
              activeCampaign: _vm.activeCampaign,
              visible: _vm.editAreaDialogVisible
            },
            on: {
              "update:visible": function($event) {
                _vm.editAreaDialogVisible = $event
              },
              close: _vm.closeEditAreaDialog
            }
          })
        : _vm._e(),
      _c("EditWorkingHoursDialog", {
        attrs: {
          visible: _vm.editWorkingHoursDialogVisible,
          campaign: _vm.activeCampaign
        },
        on: {
          "update:visible": function($event) {
            _vm.editWorkingHoursDialogVisible = $event
          },
          close: _vm.closeWorkingHoursEdit
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }