var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.visible,
        "before-close": _vm.handleClose,
        title:
          "Links for " + (_vm.activeNetworkSite && _vm.activeNetworkSite.url),
        width: "94%",
        top: "30px"
      }
    },
    [
      _c("OutgoingLinksFilters", {
        attrs: {
          exportLoading: _vm.exportLoading,
          "network-site-determined": ""
        },
        on: { filter: _vm.doFilter, export: _vm.doExport }
      }),
      _c("div", { staticClass: "mb-2" }),
      _c(
        "div",
        { staticClass: "action-bar" },
        [
          _c("PageLimitSwitcher", {
            attrs: { active: _vm.filterObject.per_page },
            on: { click: _vm.setPageLimit }
          }),
          _vm.outgoingLinksList
            ? _c("el-pagination", {
                attrs: {
                  background: "",
                  layout: "prev, pager, next",
                  "page-size": _vm.filterObject.per_page,
                  "current-page": _vm.filterObject.page,
                  total: _vm.outgoingLinksList.total,
                  "hide-on-single-page": ""
                },
                on: { "current-change": _vm.changePage }
              })
            : _vm._e(),
          _c("div", { staticClass: "table-total" }, [
            _vm._v(
              " Total Items: " +
                _vm._s(
                  (_vm.outgoingLinksList && _vm.outgoingLinksList.total) || "-"
                ) +
                " "
            )
          ])
        ],
        1
      ),
      _c("OutgoingLinksList", {
        attrs: { loading: _vm.loading, "network-site-determined": "" },
        on: { sort: _vm.doSort }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }