var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      staticClass: "filter-form",
      attrs: { model: _vm.filters, "label-position": "top" }
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "Campaign categories" } },
        [
          _c(
            "el-select",
            {
              staticClass: "multiselect",
              attrs: { filterable: "", multiple: "" },
              on: { change: _vm.doFilter },
              model: {
                value: _vm.filters.campaign_category_ids,
                callback: function($$v) {
                  _vm.$set(_vm.filters, "campaign_category_ids", $$v)
                },
                expression: "filters.campaign_category_ids"
              }
            },
            _vm._l(_vm.campaignCategories, function(category) {
              return _c("el-option", {
                key: category.id,
                attrs: { label: category.name, value: category.id }
              })
            }),
            1
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Year" } },
        [
          _c(
            "el-select",
            {
              staticClass: "multiselect",
              on: { change: _vm.doFilter },
              model: {
                value: _vm.filters.year,
                callback: function($$v) {
                  _vm.$set(_vm.filters, "year", $$v)
                },
                expression: "filters.year"
              }
            },
            _vm._l(_vm.years, function(year) {
              return _c("el-option", {
                key: year.value,
                attrs: { label: year.label, value: year.value }
              })
            }),
            1
          )
        ],
        1
      ),
      _vm.isFiltersChanged
        ? _c(
            "el-button",
            { attrs: { type: "gray" }, on: { click: _vm.clearFilters } },
            [_vm._v(" Reset filters ")]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }