var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ]
    },
    [
      (_vm.timezones && _vm.list && _vm.list.length) || _vm.loading
        ? _c("div", { staticClass: "table-wrapper" }, [
            _c("table", { staticClass: "table" }, [
              _c("thead", [
                _c("tr", [
                  _c(
                    "th",
                    [
                      _c("SortLabel", {
                        attrs: {
                          label: "Client Name",
                          order: _vm.$_sortingMixin_checkOrder("name")
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.doSort("name")
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "th",
                    [
                      _c("SortLabel", {
                        attrs: {
                          label: "Client Email",
                          order: _vm.$_sortingMixin_checkOrder("email")
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.doSort("email")
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "th",
                    { staticClass: "text-center" },
                    [
                      _c("SortLabel", {
                        attrs: {
                          label: "Notifications",
                          order: _vm.$_sortingMixin_checkOrder(
                            "disable_notifications"
                          )
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.doSort("disable_notifications")
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "th",
                    [
                      _c("SortLabel", {
                        attrs: {
                          label: "Client timezone",
                          order: _vm.$_sortingMixin_checkOrder("timezone")
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.doSort("timezone")
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c("th", [_vm._v(" Campaigns ")]),
                  _c("th", { staticClass: "text-center" }, [
                    _vm._v(" Active ")
                  ]),
                  _c("th", { staticClass: "fixed-right-column text-center" }, [
                    _vm._v("Actions")
                  ])
                ])
              ]),
              _vm.list
                ? _c(
                    "tbody",
                    _vm._l(_vm.list, function(client) {
                      return _c("tr", { key: client.id }, [
                        _c("td", [_vm._v(" " + _vm._s(client.name) + " ")]),
                        _c("td", [
                          _vm._v(
                            " " + _vm._s(client.user && client.user.email) + " "
                          )
                        ]),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                client.disable_notifications
                                  ? "Disabled"
                                  : "Enabled"
                              ) +
                              " "
                          )
                        ]),
                        _c("td", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.getFormattedTimezone(client.timezone)
                              ) +
                              " "
                          )
                        ]),
                        _c(
                          "td",
                          _vm._l(client.campaigns, function(campaign) {
                            return _c("div", { key: campaign.id }, [
                              _vm._v(" " + _vm._s(campaign.name) + " ")
                            ])
                          }),
                          0
                        ),
                        _c(
                          "td",
                          { staticClass: "text-center" },
                          [
                            _c("el-switch", {
                              attrs: {
                                value: _vm.checkActivity(client.user),
                                "active-color": "#67C23A"
                              },
                              on: {
                                change: function($event) {
                                  return _vm.changeActivity(client)
                                }
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "td",
                          {
                            staticClass:
                              "fixed-right-column table__action-column"
                          },
                          [
                            _c(
                              "el-button-group",
                              [
                                _c("el-button", {
                                  attrs: {
                                    type: "primary",
                                    size: "mini",
                                    icon: "el-icon-edit",
                                    title: "Edit"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.handleEdit(client)
                                    }
                                  }
                                }),
                                _c("el-button", {
                                  attrs: {
                                    type: "danger",
                                    size: "mini",
                                    title: "Delete",
                                    icon: "el-icon-delete"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.handleDelete(client)
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ])
                    }),
                    0
                  )
                : _vm._e()
            ])
          ])
        : _c("EmptyList")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }