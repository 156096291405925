var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "page-title-group" },
        [
          _c("h1", [_vm._v("301 domains List")]),
          _c(
            "el-button",
            {
              attrs: { type: "accent", icon: "el-icon-plus" },
              on: { click: _vm.openAddRedirectDialog }
            },
            [_vm._v(" Add 301 domain ")]
          )
        ],
        1
      ),
      _c(
        "FilterPanel",
        [_c("SearchFilter", { on: { filter: _vm.doFilter } })],
        1
      ),
      _c(
        "MainPanel",
        [
          _c(
            "div",
            { staticClass: "action-bar" },
            [
              _c("PageLimitSwitcher", {
                attrs: { active: _vm.filterObject.per_page },
                on: { click: _vm.setPageLimit }
              }),
              _vm.redirectDomains
                ? _c("el-pagination", {
                    attrs: {
                      background: "",
                      layout: "prev, pager, next",
                      "page-size": _vm.filterObject.per_page,
                      "current-page": _vm.filterObject.page,
                      total: _vm.redirectDomains.total,
                      "hide-on-single-page": ""
                    },
                    on: { "current-change": _vm.changePage }
                  })
                : _vm._e(),
              _c("div", { staticClass: "table-total" }, [
                _vm._v(
                  " Total Items: " +
                    _vm._s(_vm.redirectDomains && _vm.redirectDomains.total) +
                    " "
                )
              ])
            ],
            1
          ),
          _c("RedirectDomainsList", {
            attrs: { loading: _vm.loading },
            on: { sort: _vm.doSort, "refetch-data": _vm.getRedirectDomains }
          })
        ],
        1
      ),
      _c("AddRedirectDialog", {
        attrs: { visible: _vm.addRedirectDialogVisible },
        on: {
          "update:visible": function($event) {
            _vm.addRedirectDialogVisible = $event
          },
          close: _vm.closeAddRedirectDialog,
          "refetch-data": function($event) {
            return _vm.getRedirectDomains()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }