var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "page-title-group" }, [
        _c("h1", [
          _vm._v(_vm._s(_vm.isLeadsArchive ? "Leads Archive" : "Leads"))
        ]),
        _c(
          "div",
          { staticClass: "el-button-group" },
          [
            _c("el-button", { on: { click: _vm.openColumnSelectorDialog } }, [
              _c("i", { staticClass: "fas fa-border-all" }),
              _vm._v(" Select Table Columns ")
            ]),
            _vm.isAdminOrSuperAdmin
              ? _c(
                  "el-button",
                  {
                    on: {
                      click: function($event) {
                        return _vm.$router.push({ name: "LeadsRelated" })
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "fas fa-link" }),
                    _vm._v(" Cross Campaign Related ")
                  ]
                )
              : _vm._e(),
            !_vm.isLeadsArchive
              ? _c(
                  "el-button",
                  {
                    attrs: { loading: _vm.exportLoading },
                    on: { click: _vm.doExport }
                  },
                  [
                    _c("i", { staticClass: "fas fa-cloud-download-alt" }),
                    _vm._v(" Export ")
                  ]
                )
              : _vm._e(),
            _vm.isAdminOrSuperAdmin
              ? _c("el-button", { on: { click: _vm.openBlacklistDialog } }, [
                  _c("i", { staticClass: "fas fa-comment-slash" }),
                  _vm._v(" Blacklist ")
                ])
              : _vm._e(),
            _vm.isAdminOrSuperAdmin
              ? _c("el-button", { on: { click: _vm.openSpammersDialog } }, [
                  _c("i", { staticClass: "fas fa-users-slash" }),
                  _vm._v(" Spammers ")
                ])
              : _vm._e(),
            _vm.isAdminOrSuperAdmin
              ? _c(
                  "el-button",
                  {
                    attrs: { icon: "el-icon-plus", type: "accent" },
                    on: { click: _vm.openAddEditDialog }
                  },
                  [_vm._v(" Add Lead ")]
                )
              : _vm._e()
          ],
          1
        )
      ]),
      _c(
        "FilterPanel",
        [
          _c("LeadsFilters", {
            ref: "filterPanel",
            attrs: {
              defaultDataValues: _vm.defaultDataValues,
              loading: _vm.filtersLoading
            },
            on: { filter: _vm.doFilter }
          })
        ],
        1
      ),
      _c(
        "MainPanel",
        [
          _c(
            "div",
            { staticClass: "action-bar" },
            [
              _c("PageLimitSwitcher", {
                attrs: { active: _vm.filterObject.per_page },
                on: { click: _vm.setPageLimit }
              }),
              _vm.leads
                ? _c("el-pagination", {
                    attrs: {
                      "current-page": _vm.filterObject.page,
                      "page-size": _vm.filterObject.per_page,
                      total: _vm.leads.meta.total,
                      background: "",
                      "hide-on-single-page": "",
                      layout: "prev, pager, next"
                    },
                    on: { "current-change": _vm.changePage }
                  })
                : _vm._e(),
              _vm.leads
                ? _c("div", { staticClass: "table-total" }, [
                    _vm._v(
                      " Total Items: " +
                        _vm._s(
                          (_vm.leads.meta && _vm.leads.meta.total) || "-"
                        ) +
                        " "
                    )
                  ])
                : _vm._e()
            ],
            1
          ),
          _c("LeadsList", {
            attrs: { list: _vm.leads && _vm.leads.data, loading: _vm.loading },
            on: {
              filter: _vm.triggerFilter,
              sort: _vm.doSort,
              "open-related-leads-dialog": _vm.openRelatedLeadsDialog,
              "refetch-data": function($event) {
                return _vm.getLeads(true)
              }
            }
          })
        ],
        1
      ),
      _c("ColumnSelectorDialog", {
        attrs: { visible: _vm.columnSelectorDialogVisible },
        on: {
          "update:visible": function($event) {
            _vm.columnSelectorDialogVisible = $event
          },
          close: _vm.closeColumnSelectorDialog
        }
      }),
      _c("RelatedLeadsDialog", {
        attrs: { visible: _vm.relatedLeadsDialogVisible },
        on: {
          "update:visible": function($event) {
            _vm.relatedLeadsDialogVisible = $event
          },
          close: _vm.closeRelatedLeadsDialog
        }
      }),
      _vm.isAdminOrSuperAdmin
        ? _c("AddEditLeadDialog", {
            attrs: { visible: _vm.addEditDialogVisible },
            on: {
              "update:visible": function($event) {
                _vm.addEditDialogVisible = $event
              },
              close: _vm.closeAddEditDialog
            }
          })
        : _vm._e(),
      _vm.isAdminOrSuperAdmin
        ? _c("BlacklistDialog", {
            attrs: { visible: _vm.blacklistDialogVisible },
            on: {
              "update:visible": function($event) {
                _vm.blacklistDialogVisible = $event
              },
              close: _vm.closeBlacklistDialog,
              "refetch-data": function($event) {
                return _vm.getLeads(true)
              }
            }
          })
        : _vm._e(),
      _vm.isAdminOrSuperAdmin
        ? _c("SpammersDialog", {
            attrs: { visible: _vm.spammersDialogVisible },
            on: {
              "update:visible": function($event) {
                _vm.spammersDialogVisible = $event
              },
              close: _vm.closeSpammersDialog
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }