var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ]
    },
    [
      (_vm.list && _vm.list.length) || _vm.loading
        ? _c("div", { staticClass: "table-wrapper" }, [
            _c("table", { staticClass: "table" }, [
              _c("thead", [
                _c("tr", [
                  _c(
                    "th",
                    [
                      _c("SortLabel", {
                        attrs: {
                          label: "IP Address",
                          order: _vm.$_sortingMixin_checkOrder("ip")
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.$_sortingMixin_doSort("ip")
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "th",
                    { staticClass: "text-center" },
                    [
                      _c("SortLabel", {
                        attrs: {
                          label: "Network Sites",
                          order: _vm.$_sortingMixin_checkOrder(
                            "network_sites_count"
                          )
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.$_sortingMixin_doSort(
                              "network_sites_count"
                            )
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "th",
                    { staticClass: "text-center" },
                    [
                      _c("SortLabel", {
                        attrs: {
                          label: "Client Sites",
                          order: _vm.$_sortingMixin_checkOrder(
                            "client_sites_count"
                          )
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.$_sortingMixin_doSort(
                              "client_sites_count"
                            )
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "th",
                    [
                      _c("SortLabel", {
                        attrs: {
                          label: "Owner",
                          order: _vm.$_sortingMixin_checkOrder("owner")
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.$_sortingMixin_doSort("owner")
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "th",
                    [
                      _c("SortLabel", {
                        attrs: {
                          label: "Country",
                          order: _vm.$_sortingMixin_checkOrder("country")
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.$_sortingMixin_doSort("country")
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "th",
                    [
                      _c("SortLabel", {
                        attrs: {
                          label: "Region",
                          order: _vm.$_sortingMixin_checkOrder("region")
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.$_sortingMixin_doSort("region")
                          }
                        }
                      })
                    ],
                    1
                  )
                ])
              ]),
              _vm.list
                ? _c(
                    "tbody",
                    _vm._l(_vm.list, function(item) {
                      return _c("tr", { key: item.id }, [
                        _c("td", [_vm._v(_vm._s(item.ip))]),
                        _c(
                          "td",
                          { staticClass: "text-center" },
                          [
                            item.network_sites_count
                              ? _c(
                                  "router-link",
                                  {
                                    staticClass: "external-link",
                                    attrs: {
                                      to: {
                                        name: "NetworkSites",
                                        params: { ip: item.ip }
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(item.network_sites_count) +
                                        " "
                                    ),
                                    _c("i", {
                                      staticClass: "fas fa-external-link-alt"
                                    })
                                  ]
                                )
                              : _c("div", [
                                  _vm._v(
                                    " " + _vm._s(item.network_sites_count) + " "
                                  )
                                ])
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "text-center" },
                          [
                            item.client_sites_count
                              ? _c(
                                  "router-link",
                                  {
                                    staticClass: "external-link",
                                    attrs: {
                                      to: {
                                        name: "ClientSites",
                                        params: { ip: item.ip }
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(item.client_sites_count) +
                                        " "
                                    ),
                                    _c("i", {
                                      staticClass: "fas fa-external-link-alt"
                                    })
                                  ]
                                )
                              : _c("div", [
                                  _vm._v(
                                    " " + _vm._s(item.client_sites_count) + " "
                                  )
                                ])
                          ],
                          1
                        ),
                        _c("td", [_vm._v(_vm._s(item.owner))]),
                        _c("td", [_vm._v(_vm._s(item.country))]),
                        _c("td", [_vm._v(_vm._s(item.region))])
                      ])
                    }),
                    0
                  )
                : _vm._e()
            ])
          ])
        : _c("EmptyList")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }