var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.log && _vm.log.data.length
        ? _c(
            "div",
            [
              _c(
                "div",
                { staticClass: "action-bar action-bar--without-paginator" },
                [
                  _c("PageLimitSwitcher", {
                    attrs: { active: _vm.filterObject.per_page },
                    on: { click: _vm.setPageLimit }
                  }),
                  _c("div", { staticClass: "table-total" }, [
                    _vm._v(
                      " Total Items: " + _vm._s(_vm.log.total || "-") + " "
                    )
                  ])
                ],
                1
              ),
              _c("el-pagination", {
                attrs: {
                  background: "",
                  layout: "prev, pager, next",
                  "page-size": _vm.filterObject.per_page,
                  "current-page": _vm.filterObject.page,
                  total: _vm.log.total,
                  "hide-on-single-page": ""
                },
                on: { "current-change": _vm.changePage }
              })
            ],
            1
          )
        : _vm._e(),
      _c("SiteLogList", {
        attrs: { loading: _vm.loading, log: _vm.log && _vm.log.data }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }