var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      attrs: { model: _vm.form, rules: _vm.rules, "label-position": "top" }
    },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.visible,
            "before-close": _vm.handleClose,
            title: _vm.isEdit
              ? "Edit Campaign Category"
              : "Create Campaign Category",
            width: "94%"
          }
        },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-sm-8" },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "Category Name", prop: "name" } },
                  [
                    _c("el-input", {
                      model: {
                        value: _vm.form.name,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "name", $$v)
                        },
                        expression: "form.name"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-sm-4" },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "Category Color", prop: "name" } },
                  [
                    _c("el-color-picker", {
                      model: {
                        value: _vm.form.color,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "color", $$v)
                        },
                        expression: "form.color"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("Cancel")
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                [_vm._v("Save")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }