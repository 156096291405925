import { render, staticRenderFns } from "./IPsList.vue?vue&type=template&id=23245ea7&scoped=true&"
import script from "./IPsList.vue?vue&type=script&lang=js&"
export * from "./IPsList.vue?vue&type=script&lang=js&"
import style0 from "./IPsList.vue?vue&type=style&index=0&id=23245ea7&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23245ea7",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/source/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('23245ea7')) {
      api.createRecord('23245ea7', component.options)
    } else {
      api.reload('23245ea7', component.options)
    }
    module.hot.accept("./IPsList.vue?vue&type=template&id=23245ea7&scoped=true&", function () {
      api.rerender('23245ea7', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/ips/IPsList.vue"
export default component.exports