var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ]
    },
    [_c("div", { ref: "chart", staticClass: "chart" })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }