var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "page-title-group" },
        [
          _c("h1", [_vm._v("Clients")]),
          _c(
            "el-button",
            {
              attrs: { type: "accent", icon: "el-icon-plus" },
              on: { click: _vm.openAddEditDialog }
            },
            [_vm._v(" Create New Client ")]
          )
        ],
        1
      ),
      _c(
        "FilterPanel",
        [_c("SearchFilter", { on: { filter: _vm.doFilter } })],
        1
      ),
      _c(
        "MainPanel",
        [
          _c(
            "div",
            { staticClass: "action-bar" },
            [
              _c("PageLimitSwitcher", {
                attrs: { active: _vm.filterObject.per_page },
                on: { click: _vm.setPageLimit }
              }),
              _vm.list
                ? _c("el-pagination", {
                    attrs: {
                      background: "",
                      layout: "prev, pager, next",
                      "page-size": _vm.filterObject.per_page,
                      "current-page": _vm.filterObject.page,
                      total: _vm.list.meta.total,
                      "hide-on-single-page": ""
                    },
                    on: { "current-change": _vm.changePage }
                  })
                : _vm._e(),
              _c("div", { staticClass: "table-total" }, [
                _vm._v(
                  " Total Items: " +
                    _vm._s((_vm.list && _vm.list.meta.total) || "-") +
                    " "
                )
              ])
            ],
            1
          ),
          _c("ClientsList", {
            attrs: { loading: _vm.loading, list: _vm.list && _vm.list.data },
            on: {
              edit: _vm.openAddEditDialog,
              "refetch-data": _vm.getClientsList,
              sort: _vm.doSort
            }
          })
        ],
        1
      ),
      _c("AddEditClientDialog", {
        attrs: { visible: _vm.addEditDialogVisible },
        on: {
          "update:visible": function($event) {
            _vm.addEditDialogVisible = $event
          },
          close: _vm.closeAddEditDialog,
          "refetch-data": _vm.getClientsList
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }