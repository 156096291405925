var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "sort-label",
      class: {
        "sort-asc": _vm.order === "asc",
        "sort-desc": _vm.order === "desc"
      }
    },
    [
      _c("div", { staticClass: "sort-label__label" }, [
        _vm._v(" " + _vm._s(_vm.label) + " ")
      ]),
      _vm._m(0)
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "sort-label__icon" }, [
      _c("i", { staticClass: "fas fa-sort-up" }),
      _c("i", { staticClass: "fas fa-sort-down" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }