var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ]
    },
    [
      (_vm.list && _vm.list.length) || _vm.loading
        ? _c("div", { staticClass: "table-wrapper" }, [
            _c("table", { staticClass: "table" }, [
              _c("thead", [
                _c("tr", [
                  _c(
                    "th",
                    [
                      _c("SortLabel", {
                        attrs: {
                          label: "URL",
                          order: _vm.$_sortingMixin_checkOrder("url")
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.$_sortingMixin_doSort("url")
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "th",
                    [
                      _c("SortLabel", {
                        attrs: {
                          label: "Anchor",
                          order: _vm.$_sortingMixin_checkOrder("anchor")
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.$_sortingMixin_doSort("anchor")
                          }
                        }
                      })
                    ],
                    1
                  ),
                  !_vm.networkSiteDetermined
                    ? _c(
                        "th",
                        [
                          _c("SortLabel", {
                            attrs: {
                              label: "Network Site",
                              order: _vm.$_sortingMixin_checkOrder(
                                "network_site"
                              )
                            },
                            nativeOn: {
                              click: function($event) {
                                return _vm.$_sortingMixin_doSort("network_site")
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "th",
                    [
                      _c("SortLabel", {
                        attrs: {
                          label: "Rel",
                          order: _vm.$_sortingMixin_checkOrder("rel")
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.$_sortingMixin_doSort("rel")
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "th",
                    [
                      _c("SortLabel", {
                        attrs: {
                          label: "Content Type",
                          order: _vm.$_sortingMixin_checkOrder("type")
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.$_sortingMixin_doSort("type")
                          }
                        }
                      })
                    ],
                    1
                  )
                ])
              ]),
              _vm.list
                ? _c(
                    "tbody",
                    _vm._l(_vm.list, function(item) {
                      return _c("tr", { key: item.id }, [
                        _c("td", { staticClass: "url-cell" }, [
                          _vm._v(" " + _vm._s(item.url) + " ")
                        ]),
                        _c("td", [_vm._v(_vm._s(item.anchor))]),
                        !_vm.networkSiteDetermined
                          ? _c("td", [
                              _vm._v(" " + _vm._s(item.network_site) + " ")
                            ])
                          : _vm._e(),
                        _c("td", [_vm._v(_vm._s(item.rel))]),
                        _c("td", [_vm._v(_vm._s(item.type))])
                      ])
                    }),
                    0
                  )
                : _vm._e()
            ])
          ])
        : _c("EmptyList")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }