var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ]
    },
    [
      _c("table", { staticClass: "table" }, [
        _vm._m(0),
        _c("tbody", [
          _c("tr", [
            _c("td", [_vm._v(_vm._s(_vm.campaign.client))]),
            _c(
              "td",
              { staticClass: "text-center" },
              [
                _c("el-switch", {
                  attrs: { "active-value": 1, "inactive-value": 0 },
                  on: { change: _vm.switchPPC },
                  model: {
                    value: _vm.campaign.is_ppc,
                    callback: function($$v) {
                      _vm.$set(_vm.campaign, "is_ppc", $$v)
                    },
                    expression: "campaign.is_ppc"
                  }
                })
              ],
              1
            ),
            _c("td", { staticClass: "text-center" }, [
              _vm._v(_vm._s(_vm.campaign.leads_target))
            ]),
            _c("td", { staticClass: "text-center verified-cell" }, [
              _vm._v(_vm._s(_vm.campaign.current_leads))
            ]),
            _c("td", { staticClass: "text-center missed-cell" }, [
              _vm._v(_vm._s(_vm.campaign.missed_leads))
            ]),
            _c("td", { staticClass: "text-center" }, [
              _vm._v(_vm._s(_vm.campaign.days_left))
            ]),
            _c("td", { staticClass: "text-center" }, [
              _vm._v(_vm._s(_vm.campaign.run_rate))
            ])
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Client")]),
        _c("th", { staticClass: "text-center" }, [_vm._v("PPC")]),
        _c("th", { staticClass: "text-center" }, [_vm._v("Target")]),
        _c("th", { staticClass: "text-center" }, [_vm._v("Verified")]),
        _c("th", { staticClass: "text-center" }, [_vm._v("Missed")]),
        _c("th", { staticClass: "text-center" }, [_vm._v("Days Left")]),
        _c("th", { staticClass: "text-center" }, [_vm._v("Run Rate")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }