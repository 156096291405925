var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      attrs: { model: _vm.form, rules: _vm.rules, "label-position": "top" }
    },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.visible,
            "before-close": _vm.handleClose,
            title: _vm.isEdit
              ? "Edit Activity Log Item"
              : "Create Activity Log Item",
            width: "94%"
          }
        },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-8" },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: { label: "Campaigns or teams", prop: "campaign_ids" }
                  },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          disabled: _vm.isEdit,
                          multiple: "",
                          filterable: ""
                        },
                        model: {
                          value: _vm.form.campaign_ids,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "campaign_ids", $$v)
                          },
                          expression: "form.campaign_ids"
                        }
                      },
                      _vm._l(_vm.campaignsWithTeams, function(campaign) {
                        return _c("el-option", {
                          key: campaign.id,
                          attrs: { label: campaign.name, value: campaign.id }
                        })
                      }),
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-4" },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "Date", prop: "date" } },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        type: "date",
                        format: "MM/dd/yyyy",
                        "value-format": "yyyy-MM-dd",
                        "picker-options": _vm.pickerOptions,
                        clearable: false,
                        disabled: _vm.isEdit
                      },
                      model: {
                        value: _vm.form.date,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "date", $$v)
                        },
                        expression: "form.date"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            "el-form-item",
            { staticClass: "switch-wrapper" },
            [
              _c("el-switch", {
                attrs: { "active-text": "Task" },
                on: { change: _vm.switchIsTask },
                model: {
                  value: _vm.form.is_task,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "is_task", $$v)
                  },
                  expression: "form.is_task"
                }
              })
            ],
            1
          ),
          _vm.form.is_task
            ? _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-sm-6" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "Admin user", prop: "assignee_id" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { filterable: "" },
                            model: {
                              value: _vm.form.assignee_id,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "assignee_id", $$v)
                              },
                              expression: "form.assignee_id"
                            }
                          },
                          _vm._l(_vm.adminsWithSuperAdmin, function(admin) {
                            return _c("el-option", {
                              key: admin.id,
                              attrs: { label: admin.username, value: admin.id }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "Note", prop: "note" } },
            [
              _c("el-input", {
                staticClass: "note-input",
                attrs: { type: "textarea", rows: 8 },
                model: {
                  value: _vm.form.note,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "note", $$v)
                  },
                  expression: "form.note"
                }
              })
            ],
            1
          ),
          _c("FileUploader", {
            attrs: {
              action: "/campaign-changelog/files",
              files: _vm.form.files
            },
            on: { "add-file": _vm.addFile, "delete-file": _vm.deleteFile }
          }),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _vm.isEdit
                ? _c(
                    "el-button",
                    { attrs: { type: "danger" }, on: { click: _vm.onDelete } },
                    [_vm._v(" Delete ")]
                  )
                : _vm._e(),
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v(" Cancel ")
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                [_vm._v(" Save ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }