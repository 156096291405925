var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.visible,
        "before-close": _vm.closeModal,
        title: "Connected accounts",
        width: "94%"
      }
    },
    [
      _c("div", { staticClass: "table-wrapper-alt" }, [
        _c(
          "table",
          { staticClass: "table" },
          _vm._l(_vm.list, function(item) {
            return _c("tr", { key: item.id }, [
              _c("td", [_vm._v(" " + _vm._s(item.account_name) + " ")]),
              _c("td", { staticClass: "text-center" }, [
                _vm._v(" " + _vm._s(item.email) + " ")
              ]),
              _c(
                "td",
                { staticClass: "text-right" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "danger", size: "mini" },
                      on: {
                        click: function($event) {
                          return _vm.unlinkAccount(item.id)
                        }
                      }
                    },
                    [_vm._v(" Unlink ")]
                  )
                ],
                1
              )
            ])
          }),
          0
        )
      ]),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "accent", icon: "el-icon-plus" },
              on: { click: _vm.linkNewAccount }
            },
            [_vm._v(" Add New Account ")]
          ),
          _c("el-button", { on: { click: _vm.closeModal } }, [_vm._v("Close")])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }