var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "action-bar" },
      [
        _c("PageLimitSwitcher", {
          attrs: { active: _vm.filterObject.per_page },
          on: { click: _vm.setPageLimit }
        }),
        _vm.campaigns
          ? _c("el-pagination", {
              attrs: {
                "current-page": _vm.filterObject.page,
                "page-size": _vm.filterObject.per_page,
                total: _vm.campaigns.meta.total,
                background: "",
                "hide-on-single-page": "",
                layout: "prev, pager, next"
              },
              on: { "current-change": _vm.changePage }
            })
          : _vm._e(),
        _c("div", { staticClass: "table-total" }, [
          _vm._v(
            " Total Campaigns: " +
              _vm._s((_vm.summary && _vm.summary.total) || "-") +
              " "
          )
        ])
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading"
          }
        ]
      },
      [
        _c("div", { staticClass: "table-wrapper" }, [
          _c("table", { staticClass: "table" }, [
            _c("thead", [
              _c("tr", [
                _c(
                  "th",
                  { staticClass: "fixed-left-column" },
                  [
                    _c("SortLabel", {
                      attrs: {
                        order: _vm.$_sortingMixin_checkOrder("name"),
                        label: "Name"
                      },
                      nativeOn: {
                        click: function($event) {
                          return _vm.doSort("name")
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "th",
                  [
                    _c("SortLabel", {
                      attrs: {
                        order: _vm.$_sortingMixin_checkOrder(
                          "campaigncategory.name"
                        ),
                        label: "Category"
                      },
                      nativeOn: {
                        click: function($event) {
                          return _vm.doSort("campaigncategory.name")
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "th",
                  [
                    _c("SortLabel", {
                      attrs: {
                        order: _vm.$_sortingMixin_checkOrder("client.name"),
                        label: "Client"
                      },
                      nativeOn: {
                        click: function($event) {
                          return _vm.doSort("client.name")
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "th",
                  [
                    _c("SortLabel", {
                      attrs: {
                        order: _vm.$_sortingMixin_checkOrder("client_sites"),
                        label: "Client Sites"
                      },
                      nativeOn: {
                        click: function($event) {
                          return _vm.doSort("client_sites")
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "th",
                  [
                    _c("SortLabel", {
                      attrs: {
                        order: _vm.$_sortingMixin_checkOrder("manager"),
                        label: "Manager"
                      },
                      nativeOn: {
                        click: function($event) {
                          return _vm.doSort("manager")
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "th",
                  { staticClass: "text-center" },
                  [
                    _c("SortLabel", {
                      attrs: {
                        order: _vm.$_sortingMixin_checkOrder("start_date"),
                        label: "Start Date"
                      },
                      nativeOn: {
                        click: function($event) {
                          return _vm.doSort("start_date")
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "th",
                  { staticClass: "text-center" },
                  [
                    _c("SortLabel", {
                      attrs: {
                        order: _vm.$_sortingMixin_checkOrder("due_date"),
                        label: "Due Date"
                      },
                      nativeOn: {
                        click: function($event) {
                          return _vm.doSort("due_date")
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "th",
                  { staticClass: "text-center" },
                  [
                    _c("SortLabel", {
                      attrs: {
                        order: _vm.$_sortingMixin_checkOrder("current_target"),
                        label: "Current Target"
                      },
                      nativeOn: {
                        click: function($event) {
                          return _vm.doSort("current_target")
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "th",
                  { staticClass: "text-center" },
                  [
                    _c("SortLabel", {
                      attrs: {
                        order: _vm.$_sortingMixin_checkOrder("initial_target"),
                        label: "Initial Target"
                      },
                      nativeOn: {
                        click: function($event) {
                          return _vm.doSort("initial_target")
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "th",
                  { staticClass: "text-center" },
                  [
                    _c("SortLabel", {
                      attrs: {
                        order: _vm.$_sortingMixin_checkOrder("month_3_target"),
                        label: "3 Month Target"
                      },
                      nativeOn: {
                        click: function($event) {
                          return _vm.doSort("month_3_target")
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "th",
                  { staticClass: "text-center" },
                  [
                    _c("SortLabel", {
                      attrs: {
                        order: _vm.$_sortingMixin_checkOrder("month_6_target"),
                        label: "6 Month Target"
                      },
                      nativeOn: {
                        click: function($event) {
                          return _vm.doSort("month_6_target")
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "th",
                  { staticClass: "text-center" },
                  [
                    _c("SortLabel", {
                      attrs: {
                        order: _vm.$_sortingMixin_checkOrder("month_9_target"),
                        label: "9 Month Target"
                      },
                      nativeOn: {
                        click: function($event) {
                          return _vm.doSort("month_9_target")
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "th",
                  { staticClass: "text-center" },
                  [
                    _c("SortLabel", {
                      attrs: {
                        order: _vm.$_sortingMixin_checkOrder("month_12_target"),
                        label: "12 Month Target"
                      },
                      nativeOn: {
                        click: function($event) {
                          return _vm.doSort("month_12_target")
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "th",
                  { staticClass: "text-center" },
                  [
                    _c("SortLabel", {
                      attrs: {
                        order: _vm.$_sortingMixin_checkOrder("service_fee"),
                        label: "Service Fee"
                      },
                      nativeOn: {
                        click: function($event) {
                          return _vm.doSort("service_fee")
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "th",
                  { staticClass: "text-center" },
                  [
                    _c("SortLabel", {
                      attrs: {
                        order: _vm.$_sortingMixin_checkOrder("approved_ppc"),
                        label: "Approved PPC"
                      },
                      nativeOn: {
                        click: function($event) {
                          return _vm.doSort("approved_ppc")
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "th",
                  { staticClass: "text-center" },
                  [
                    _c("SortLabel", {
                      attrs: {
                        order: _vm.$_sortingMixin_checkOrder("ppc"),
                        label: "PPC %"
                      },
                      nativeOn: {
                        click: function($event) {
                          return _vm.doSort("ppc")
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "th",
                  { staticClass: "text-center" },
                  [
                    _c("SortLabel", {
                      attrs: {
                        order: _vm.$_sortingMixin_checkOrder("net"),
                        label: "Net"
                      },
                      nativeOn: {
                        click: function($event) {
                          return _vm.doSort("net")
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "th",
                  { staticClass: "text-center" },
                  [
                    _c("SortLabel", {
                      attrs: {
                        order: _vm.$_sortingMixin_checkOrder("gmbs_count"),
                        label: "GMBs"
                      },
                      nativeOn: {
                        click: function($event) {
                          return _vm.doSort("gmbs_count")
                        }
                      }
                    })
                  ],
                  1
                ),
                _c("th", { staticClass: "fixed-right-column text-center" }, [
                  _vm._v("Actions")
                ])
              ])
            ]),
            _vm.campaigns
              ? _c(
                  "tbody",
                  _vm._l(_vm.campaigns && _vm.campaigns.data, function(
                    campaign,
                    index
                  ) {
                    return _c(
                      "tr",
                      {
                        key: campaign.id + "-" + index,
                        class: {
                          disactivated:
                            (campaign.archived && !_vm.isArchived) ||
                            (!campaign.archived && _vm.isArchived)
                        }
                      },
                      [
                        _c("td", { staticClass: "fixed-left-column" }, [
                          _vm._v(_vm._s(campaign.name))
                        ]),
                        _c(
                          "td",
                          _vm._l(campaign.categories, function(category) {
                            return _c(
                              "div",
                              {
                                key: category.id,
                                staticClass: "category-wrapper"
                              },
                              [
                                _c("div", {
                                  staticClass: "category-marker",
                                  style: "background: " + category.color
                                }),
                                _vm._v(" " + _vm._s(category.name) + " ")
                              ]
                            )
                          }),
                          0
                        ),
                        _c("td", [
                          _vm._v(
                            _vm._s(campaign.client && campaign.client.name)
                          )
                        ]),
                        _c(
                          "td",
                          [
                            campaign.client_sites.length
                              ? _vm._l(campaign.client_sites, function(site) {
                                  return _c(
                                    "a",
                                    {
                                      key: site.id,
                                      staticClass: "show client-website",
                                      attrs: {
                                        href: "//" + site.url,
                                        target: "_blank"
                                      }
                                    },
                                    [_vm._v(" " + _vm._s(site.url) + " ")]
                                  )
                                })
                              : _vm._e()
                          ],
                          2
                        ),
                        _c("td", [_vm._v(_vm._s(campaign.manager))]),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$_formatterMixin_formatDate(
                                campaign.start_date
                              )
                            )
                          )
                        ]),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(_vm._s(campaign.due_date))
                        ]),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(_vm._s(campaign.targets.current_target))
                        ]),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(_vm._s(campaign.targets.initial_target))
                        ]),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(_vm._s(campaign.targets.month_3_target))
                        ]),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(_vm._s(campaign.targets.month_6_target))
                        ]),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(_vm._s(campaign.targets.month_9_target))
                        ]),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(_vm._s(campaign.targets.month_12_target))
                        ]),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$_formatterMixin_formatCurrency(
                                campaign.service_fee
                              )
                            )
                          )
                        ]),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$_formatterMixin_formatCurrency(
                                campaign.approved_ppc
                              )
                            )
                          )
                        ]),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$_formatterMixin_formatPercent(campaign.ppc)
                            )
                          )
                        ]),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$_formatterMixin_formatCurrency(campaign.net)
                            )
                          )
                        ]),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(_vm._s(campaign.gmbs_count))
                        ]),
                        _c(
                          "td",
                          {
                            staticClass:
                              "fixed-right-column table__action-column"
                          },
                          [
                            _c(
                              "el-button-group",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      icon: "el-icon-edit",
                                      size: "mini",
                                      title: "Edit Campaign",
                                      type: "primary"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.editCampaign(campaign)
                                      }
                                    }
                                  },
                                  [_vm._v(" Edit ")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      icon: "el-icon-date",
                                      size: "mini",
                                      title: "Edit Working Hours"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.editWorkingHours(campaign)
                                      }
                                    }
                                  },
                                  [_vm._v(" Working hours ")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      icon: "el-icon-map-location",
                                      size: "mini",
                                      title: "Edit Campaign Area",
                                      type: "success"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.editArea(campaign)
                                      }
                                    }
                                  },
                                  [_vm._v(" Map ")]
                                ),
                                campaign.archived === 0
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          icon: "el-icon-download",
                                          size: "mini",
                                          title: "Archive Campaign",
                                          type: "danger"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.onArchive(campaign)
                                          }
                                        }
                                      },
                                      [_vm._v(" Archive ")]
                                    )
                                  : _vm._e(),
                                campaign.archived === 1
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          icon: "el-icon-upload2",
                                          size: "mini",
                                          title: "Restore Campaign",
                                          type: "default"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.onRestore(campaign)
                                          }
                                        }
                                      },
                                      [_vm._v(" Restore ")]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    )
                  }),
                  0
                )
              : _vm._e()
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }