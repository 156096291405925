var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      attrs: { model: _vm.form, rules: _vm.rules, "label-position": "top" }
    },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "before-close": _vm.handleClose,
            visible: _vm.visible,
            title: "Spammers Identifiers List",
            width: "94%"
          }
        },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-sm-5" },
              [
                _c(
                  "el-form-item",
                  { attrs: { prop: "name" } },
                  [
                    _c("label", { staticClass: "el-form-item__label" }, [
                      _vm._v(" Name ")
                    ]),
                    _c("el-input", {
                      model: {
                        value: _vm.form.name,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "name", $$v)
                        },
                        expression: "form.name"
                      }
                    })
                  ],
                  1
                ),
                _c("el-alert", {
                  staticClass: "alert",
                  attrs: { type: "info", closable: false, "show-icon": "" },
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function() {
                        return [_vm._v(" Either Phone or Email is required ")]
                      },
                      proxy: true
                    }
                  ])
                }),
                _c(
                  "el-form-item",
                  {
                    staticClass: "phone-field-wrapper",
                    attrs: { prop: "phone" }
                  },
                  [
                    _c("label", { staticClass: "el-form-item__label" }, [
                      _vm._v(" Phone ")
                    ]),
                    _c("el-input", {
                      directives: [
                        {
                          name: "mask",
                          rawName: "v-mask",
                          value: "+# ###-###-####",
                          expression: "'+# ###-###-####'"
                        }
                      ],
                      attrs: { placeholder: "+_ ___-___-____" },
                      model: {
                        value: _vm.form.phone,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "phone", $$v)
                        },
                        expression: "form.phone"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { prop: "email" } },
                  [
                    _c("label", { staticClass: "el-form-item__label" }, [
                      _vm._v(" Email ")
                    ]),
                    _c("el-input", {
                      model: {
                        value: _vm.form.email,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "email", $$v)
                        },
                        expression: "form.email"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-button",
                  {
                    attrs: {
                      disabled: !(
                        (_vm.form.phone || _vm.form.email) &&
                        _vm.form.name &&
                        _vm.form.name.length > 1
                      ),
                      icon: "el-icon-plus",
                      type: "accent"
                    },
                    on: { click: _vm.onSubmit }
                  },
                  [_vm._v(" Add ")]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-7" },
              [
                _c("SpammersList", {
                  attrs: { loading: _vm.loading },
                  on: {
                    "refetch-data": _vm.getSpammers,
                    "enable-loading": function($event) {
                      _vm.loading = true
                    }
                  }
                })
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }